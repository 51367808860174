define("lightway-connect/components/ui-form/select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    valueToSend: Ember.computed('item', 'value', function () {
      if (this.item) {
        return this.item;
      } else {
        return this.value;
      }
    }),
    stringifiedCurrentValue: Ember.computed('currentValue', function () {
      if (this.currentValue == null) {
        return 'nullz'; // IMPORTANT: String is deliberate
      } else if (this.currentValue == false) {
        return 'falsez'; // IMPORTANT: String is deliberate
      } else {
        return this.currentValue;
      }
    }),
    stringifiedValue: Ember.computed('value', function () {
      if (this.value == null) {
        return 'nullz'; // IMPORTANT: String is deliberate
      } else if (this.value == false) {
        return 'falsez'; // IMPORTANT: String is deliberate
      } else {
        return this.value;
      }
    }),
    matchesCurrentValue: Ember.computed('stringifiedCurrentValue', 'stringifiedValue', function () {
      if (!this.stringifiedCurrentValue) {
        return false;
      }

      if (this.stringifiedCurrentValue == "nullz" && this.stringifiedValue == "nullz") {
        return false;
      }

      return this.stringifiedCurrentValue == this.stringifiedValue;
    }),
    matchesCurrentItem: Ember.computed('currentItem', 'item', function () {
      if (!this.currentItem) {
        return false;
      }

      return this.currentItem == this.item;
    }),
    matchesCurrent: Ember.computed('matchesCurrentValue', 'matchesCurrentItem', function () {
      return this.matchesCurrentValue || this.matchesCurrentItem;
    }),
    currentValueNotPresentAndThisOptionIsBlank: Ember.computed('stringifiedCurrentValue', 'currentItem', function () {
      if (this.currentItem) {
        return false;
      } else {
        if (this.stringifiedValue == 'nullz') {
          if (this.stringifiedCurrentValue == 'nullz') {
            if (this.association) {
              if (!this.item) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }),
    valueId: Ember.computed('value', function () {
      if (this.item) {
        return this.item.id;
      } else {
        if (this.value == null) {
          return "null";
        }

        if (this.value && this.value.id) {
          return this.value.id;
        } else {
          if (this.value == true) {
            return "true";
          } else if (this.value == false) {
            return "false";
          } else {
            return this.value;
          }
        }
      }
    })
  });

  _exports.default = _default;
});