define("lightway-connect/templates/components/content-editor/parts/image-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBx1eW6s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"image\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[23,0,[\"image\",\"imageUrl\"]]],[11,\"alt\",[23,0,[\"image\",\"name\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"dynamicImage\"]]],null,{\"statements\":[[0,\"  \\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content dynamic-image-placeholder p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-y align-center image-placeholder-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"placeholder\"],[8],[0,\"\\n          Dynamic Image\\n        \"],[9],[0,\"\\n        \\n        \"],[7,\"span\",true],[10,\"class\",\"image-name\"],[8],[0,\"\\n          \"],[1,[23,0,[\"dynamicImage\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content image-placeholder p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-y align-center image-placeholder-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"placeholder\"],[8],[0,\"\\n          Image Placeholder\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/image-part.hbs"
    }
  });

  _exports.default = _default;
});