define("lightway-connect/mixins/parent-partable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    parent: (0, _model.belongsTo)('partParentable', {
      inverse: 'parts',
      polymorphic: true,
      async: false
    }),
    formerParent: (0, _model.belongsTo)('partParentable', {
      inverse: 'removedParts',
      polymorphic: true,
      async: false
    }),
    parentInherited: (0, _model.belongsTo)('partParentable', {
      inverse: 'inheritedParts',
      async: false
    })
  });

  _exports.default = _default;
});