define("lightway-connect/components/ui-form/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('data-test-id', "button");
    },
    type: 'button',
    disabled: Ember.computed.oneWay('task.isRunning'),
    task: null,
    onClick: function onClick() {},
    actions: {
      click: function click(event) {
        event.preventDefault();
        var task = this.task;
        this.onClick(); //let onClick = this.onClick();

        if (task) {
          task.perform(); // } else {
        }
      }
    }
  });

  _exports.default = _default;
});