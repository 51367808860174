define("lightway-connect/components/ui-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showingAll: Ember.computed('showingAllOverride', 'showOnlyCurrent', function () {
      if (this.showingAllOverride) {
        return this.showingAllOverride;
      }

      return !this.showOnlyCurrent;
    }),
    permittedToBeShown: Ember.computed('permittedToBeShownOverride', 'showOnlyCurrent', 'currentValue', function () {
      if (this.permittedToBeShownOverride) {
        return this.permittedToBeShownOverride;
      }

      if (this.showOnlyCurrent) {
        return this.isCurrentValue;
      } else {
        return true;
      }
    }),
    isCurrentValue: Ember.computed('isCurrentValueOverride', 'currentValue', function () {
      if (this.isCurrentValueOverride) {
        return this.isCurrentValueOverride;
      }

      return this.currentValue === this.value;
    })
  });

  _exports.default = _default;
});