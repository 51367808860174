define("lightway-connect/components/content-editor/position-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    tagName: '',
    positionName: Ember.computed('positionX', 'positionY', function () {
      return "".concat(this.positionY, "-").concat(this.positionX);
    }),
    currentPosition: Ember.computed('model.{positionX,positionY}', function () {
      return this.model.get('positionX') == this.positionX && this.model.get('positionY') == this.positionY;
    }),
    inheritedPosition: Ember.computed('model.{inheritedPositionX,inheritedPositionY}', function () {
      return this.model.get('inheritedPositionX') == this.positionX && this.model.get('inheritedPositionY') == this.positionY;
    }),
    actions: {
      setPosition: function setPosition(x, y) {
        var changes = {};
        changes["preferences.".concat(this.editorService.viewport.size, ".position-x")] = x;
        changes["preferences.".concat(this.editorService.viewport.size, ".position-y")] = y;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      },
      removePosition: function removePosition() {
        var changes = {};
        changes["preferences.".concat(this.editorService.viewport.size, ".position-x")] = null;
        changes["preferences.".concat(this.editorService.viewport.size, ".position-y")] = null;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }
    }
  });

  _exports.default = _default;
});