define("lightway-connect/components/ui-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    onSubmit: function onSubmit() {},
    submit: function submit(event) {
      event.preventDefault();
      this.onSubmit();
    }
  });

  _exports.default = _default;
});