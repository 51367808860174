define("lightway-connect/templates/components/accounts/change-email-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YKqOl2bg",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"formIsHidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    To confirm your new email address, please follow the instructions in the mail message we have just sent there.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"with\",[[28,\"changeset\",[[28,\"hash\",null,null],[23,0,[\"ChangeEmailFormValidations\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"form-errors\",[],[[\"@changeset\"],[[23,1,[]]]]],[0,\"\\n\\n    \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"changeEmail\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n      \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"new-email\"]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n          New Email\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[6,[23,3,[\"emailInput\"]],[],[[],[]]],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n\\n      \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Change Email\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[14,4],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/accounts/change-email-form.hbs"
    }
  });

  _exports.default = _default;
});