define("lightway-connect/templates/sites/site/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N1qapw5U",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layouts/section-bar\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"sites.site.media\"]],{\"statements\":[[0,\"\\n      Media\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"class\",\"grid-x section-menu media\"],[8],[0,\"\\n    \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"images\"],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"sites.site.media.image-folders\"]],{\"statements\":[[0,\"\\n          Images\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"documents\"],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"sites.site.media.document-folders\"]],{\"statements\":[[0,\"\\n          Documents\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"layouts/main-reduced\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media.hbs"
    }
  });

  _exports.default = _default;
});