define("lightway-connect/components/content-editor/properties/prototype", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-canvas-settings'],
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadLayouts();
      this.loadDataKinds();
    },
    loadLayouts: function loadLayouts() {
      var _this = this;

      var site = this.get('currentSite.site');
      this.store.query('layout', {
        filter: {
          site: site.id,
          id: !null
        }
      }).then(function (results) {
        _this.set('layouts', results);
      });
    },
    loadDataKinds: function loadDataKinds() {
      var _this2 = this;

      var site = this.get('currentSite.site');
      this.store.query('data-kind', {
        filter: {
          site: site.id,
          id: !null,
          "content-enabled": true
        }
      }).then(function (results) {
        _this2.set('dataKinds', results.toArray());
      });
    }
  });

  _exports.default = _default;
});