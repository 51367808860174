define("lightway-connect/templates/sites/site/media/image-folders/image-folder/image/move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKiXVsMy",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"h3\",true],[8],[0,\"Move Image to Folder\"],[9],[0,\"\\n\\n\"],[5,\"sites/media/move-image\",[],[[\"@image\",\"@folders\",\"@save\"],[[23,1,[]],[23,0,[\"folders\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/image-folders/image-folder/image/move.hbs"
    }
  });

  _exports.default = _default;
});