define("lightway-connect/templates/policies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVQkKXED",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-container full\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"top-bar section-head\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar-left\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"\\n        Policies\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"class\",\"grid-x resource-list\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x small-12 resource-list\"],[8],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"cell auto name\"],[8],[0,\"\\n            \"],[5,\"link-to\",[],[[\"@route\"],[\"policies.privacy\"]],{\"statements\":[[0,\"\\n              Privacy Policy\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/policies/index.hbs"
    }
  });

  _exports.default = _default;
});