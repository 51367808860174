define("lightway-connect/templates/components/content-editor/parts/video-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SuEGLKL/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"video\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    \"],[7,\"video\",true],[10,\"width\",\"320\"],[10,\"height\",\"240\"],[10,\"controls\",\"\"],[8],[0,\"\\n      \"],[7,\"source\",true],[11,\"src\",[23,0,[\"video\",\"documentUrl\"]]],[10,\"type\",\"video/mp4\"],[8],[9],[0,\"\\n      Your browser does not support this video.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content video-placeholder p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-y align-center video-placeholder-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"placeholder\"],[8],[0,\"\\n          Video Placeholder\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/video-part.hbs"
    }
  });

  _exports.default = _default;
});