define("lightway-connect/templates/components/content-editor/properties/data-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d+xO1YgV",
    "block": "{\"symbols\":[\"Panel\",\"Module\",\"Box\",\"prototype\",\"@model\",\"&default\"],\"statements\":[[5,\"content-editor/setting-panel\",[[12,\"class\",\"settings-panel properties\"]],[[\"@saveAction\",\"@model\",\"@isOpen\"],[[23,0,[\"saveAction\"]],[23,5,[]],true]],{\"statements\":[[0,\"\\n\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Data Collection Properties\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"module\"]],[],[[\"@identifier\",\"@property\",\"@isOpen\"],[\"basePrototype\",\"basePrototype\",true]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Type\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[6,[23,2,[\"selectBox\"]],[],[[\"@property\",\"@propertyPathOverride\"],[\"basePrototype\",\"basePrototype\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"prototypes\"]]],null,{\"statements\":[[0,\"          \"],[6,[23,3,[\"option\"]],[],[[\"@value\"],[[23,4,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,4,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,6],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/properties/data-collection.hbs"
    }
  });

  _exports.default = _default;
});