define("lightway-connect/components/ui-form/image-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    currentImage: Ember.computed('property.image.id', function () {
      if (this.referenceMode) {
        var imageId;

        if (Ember.get(this.model, this.property)) {
          imageId = Ember.get(this.model, this.property).id;
        }

        if (imageId) {
          return this.store.findRecord('image', imageId);
        }
      } else {
        return this.property.image;
      }

      return null;
    }),
    inputId: Ember.computed({
      get: function get() {
        return Ember.guidFor(this) + '-inpudid';
      }
    }),
    actions: {
      toggleModalForImage: function toggleModalForImage() {
        this.toggleProperty('isShowingModalForImage');
      },
      selectImage: function selectImage(image) {
        if (this.referenceMode) {
          var ref = {
            id: image.id,
            type: "images"
          };
          Ember.set(this.model, this.property, ref);
        } else {
          this.set('property.image', image);
        }

        this.notifyPropertyChange('currentImage');
        this.toggleProperty('isShowingModalForImage');
      },
      removeImage: function removeImage() {
        if (this.referenceMode) {
          Ember.set(this.model, this.property, null);
        } else {
          this.set('property.image', null);
        }

        this.notifyPropertyChange('currentImage');
      }
    }
  });

  _exports.default = _default;
});