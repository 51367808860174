define("lightway-connect/templates/components/content-editor/parts/region-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "amOu7sZc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"regionBelongsWithinLayoutVersionAndInLayoutMode\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    Content region\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"regionBelongsWithinMailTemplateVersionAndInMailTemplateMode\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n    Content region\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"part-content full-height p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"itemRegion\",\"parts\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      Place new parts here\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@prototypeBasedPart\",\"@items\",\"@parentXDirectionClass\",\"@parentYDirectionClass\",\"@orientationOverride\",\"@group\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,0,[\"part\"]],[23,0,[\"prototypeBasedPart\"]],[23,0,[\"itemRegion\",\"partsSortedBound\"]],[23,0,[\"parentXDirectionClass\"]],[23,0,[\"parentYDirectionClass\"]],[23,0,[\"orientation\"]],\"nested-group\",true,[23,0,[\"selectPartAction\"]],[23,0,[\"dropPartAction\"]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/region-part.hbs"
    }
  });

  _exports.default = _default;
});