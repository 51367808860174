define("lightway-connect/components/translation-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      selectLocale: function selectLocale(locale) {
        this.set('translation.locale', locale);
      },
      updateValue: function updateValue(value) {
        this.set('translation.stringValue', value);
      },
      removeTranslation: function removeTranslation() {
        this.set('showDeleteConfirmation', true);
      },
      destroyTranslation: function destroyTranslation() {
        var parent = this.translation.get('parent');
        this.translation.set('formerParent', parent);
        this.translation.set('parent', null);
      }
    }
  });

  _exports.default = _default;
});