define("lightway-connect/templates/sites/site/data-kinds/data-kind/categories/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQffQ/Y0",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\",\"@model\"],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n  New Category\\n\"],[9],[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[23,4,[\"dataCategory\"]],[23,0,[\"DataCategoryFormValidations\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"save\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Category Name\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"textInput\"]],[],[[],[]]],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\\n    \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Create Category\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/data-kinds/data-kind/categories/new.hbs"
    }
  });

  _exports.default = _default;
});