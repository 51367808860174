define("lightway-connect/components/content-editor/properties/item-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-part-settings'],
    editorService: Ember.inject.service(),
    isInherited: Ember.computed('model.{parent,parentInherited}', function () {
      if (this.model.parentInherited) {
        return true;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});