define("lightway-connect/mixins/data-property-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    properties: (0, _model.hasMany)('data-property', {
      inverse: 'item',
      async: false
    }),
    dataAssociates: (0, _model.hasMany)('data-associate', {
      inverse: 'item'
    }),
    ownedDataAssociates: (0, _model.hasMany)('data-associate', {
      inverse: 'owner'
    })
  });

  _exports.default = _default;
});