define("lightway-connect/components/content-editor/properties/page-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNames: ['current-canvas-settings'],
    subjectType: Ember.computed('model.preferences.subject-type', function () {
      var result;
      var subjectType = this.model.get('preferences.subject-type');

      if (subjectType && subjectType != {}) {
        if (subjectType.type === "user-invitations") {
          return {
            id: "user-invitations",
            name: "User Invitations"
          };
        } else if (subjectType.type === "notifications") {
          return {
            id: "notifications",
            name: "Notifications"
          };
        } else if (subjectType.type === "reset-password-confirmations") {
          return {
            id: "reset-password-confirmations",
            name: "Reset Password Confirmations"
          };
        } else {
          result = this.store.peekRecord(subjectType.type, subjectType.id);
        }
      }

      return result;
    }),
    actions: {
      toggleModalForSubjectType: function toggleModalForSubjectType() {
        this.set('selectingSubjectType', true);
      },
      cancelSubjectTypeSelection: function cancelSubjectTypeSelection() {
        this.set('selectingSubjectType', false);
      },
      selectSubjectType: function selectSubjectType(parent) {
        var subjectType;

        if (parent === "user-invitations") {
          subjectType = {
            type: 'user-invitations'
          };
        } else if (parent === "notifications") {
          subjectType = {
            type: 'notifications'
          };
        } else if (parent === "reset-password-confirmations") {
          subjectType = {
            type: 'reset-password-confirmations'
          };
        } else {
          subjectType = {
            id: parent.id,
            type: 'data-kind'
          };
        }

        var edit = this.editorService.prepareEdit(this.model, {
          'preferences.subject-type': subjectType
        });
        this.editorService.addModifications(Ember.A([edit]));
        this.set('selectingSubjectType', false);
      },
      removeSubjectType: function removeSubjectType() {
        this.model.set('preferences.subject-type', null);
      }
    }
  });

  _exports.default = _default;
});