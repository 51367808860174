define("lightway-connect/validations/accounts/sign-up-form", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    email: (0, _validators.validatePresence)(true),
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8
    })] // passwordConfirmation: validatePresence(true),

  };
  _exports.default = _default;
});