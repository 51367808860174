define("lightway-connect/templates/sites/site/data-kinds/data-kind/items/item/associates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCmnyU2g",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    Item - \"],[1,[23,1,[\"item\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"sites.site.data-kinds.data-kind.items.item\"]],{\"statements\":[[0,\"\\n      Return to Item\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/data-kinds/data-kind/items/item/associates.hbs"
    }
  });

  _exports.default = _default;
});