define("lightway-connect/components/content-editor/properties/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNames: ['current-part-settings'],
    isShowingModalForVideo: false,
    dynamicContainer: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer;
    }),
    withinDynamicContainer: Ember.computed('dynamicContainer', function () {
      return this.dynamicContainer;
    }),
    subject: Ember.computed('dynamicContainer', function () {
      return this.dynamicContainer.subject;
    }),
    withinVideoGalleryPrototype: Ember.computed('model.baseParent.kind', function () {
      if (this.model.baseParent.isA('prototype')) {
        return this.model.baseParent.kind === "video-gallery";
      }

      return false;
    }),
    withinContentOverlayPrototype: Ember.computed('model.baseParent.kind', function () {
      if (this.model.baseParent.isA('prototype')) {
        return this.model.baseParent.kind === "content-overlay";
      }

      return false;
    }),
    subjectType: Ember.computed('dynamicContainer.preferences.subject-type', function () {
      var result;

      if (this.dynamicContainer.isA('page-version')) {
        var subjectType = this.dynamicContainer.get('preferences.subject-type');

        if (subjectType && subjectType != {}) {
          this.store.peekAll(subjectType.type).forEach(function (itm) {
            if (itm.id == subjectType.id) {
              result = itm;
            }
          });
        }
      } else {
        return this.dynamicContainer;
      }

      return result;
    }),
    dynamicProperties: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.dynamicContainer.get('preferences.dynamic') == "collection") {
        var type = this.dynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          var kindId = this.dynamicContainer.get('preferences.subject-type.id');
          var kind = this.store.peekRecord('data-kind', kindId);
          var fields = Ember.A([]);
          kind.fields.forEach(function (field) {
            if (field.kind == 'video-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      }

      if (this.subjectType) {
        if (this.subjectType.type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name",
            type: "property"
          }]);
        } else {
          var _fields = Ember.A([]);

          if (this.subjectType.isA('part')) {
            if (this.subjectType.preferences.dynamic === "gallery-item") {//
            } else {
              if (this.subjectType.subjectTypeObject.isA('data-item')) {
                this.subjectType.subjectTypeObject.parent.fields.forEach(function (field) {
                  if (field.kind == 'video-field') {
                    _fields.addObject(field);
                  }
                });
              } else {
                this.subjectType.subjectTypeObject.fields.forEach(function (field) {
                  if (field.kind == 'video-field') {
                    _fields.addObject(field);
                  }
                });
              }
            }
          } else {
            this.subjectType.fields.forEach(function (field) {
              if (field.kind == 'video-field') {
                _fields.addObject(field);
              }
            });
          }

          return _fields;
        }
      } else if (this.subject) {
        // return this.subject.parent.fields
        var _fields2 = Ember.A([]);

        this.subject.parent.fields.forEach(function (field) {
          if (field.kind == 'video-field') {
            _fields2.addObject(field);
          }
        });
        return _fields2;
      } else {
        return null;
      }
    }),
    actions: {
      selectVideo: function selectVideo(video) {
        var edit = this.editorService.prepareEdit(this.model, {
          kontent: video
        });
        this.editorService.addModifications(Ember.A([edit]));
        this.set('videoPartForModal', null), this.set('isShowingModalForVideo', false);
      },
      toggleModalForVideo: function toggleModalForVideo() {
        this.toggleProperty('isShowingModalForVideo');
      },
      removeVideo: function removeVideo() {
        var edit = this.editorService.prepareEdit(this.model, {
          kontent: null
        });
        this.editorService.addModifications(Ember.A([edit]));
      },
      toggleModalForValue: function toggleModalForValue() {
        this.toggleProperty('selectingValue');
      },
      cancelValueSelection: function cancelValueSelection() {
        this.set('selectingValue', false);
      },
      selectValue: function selectValue(value, associationField) {
        var dynamicValue;

        if (value === "gallery-item-video") {
          dynamicValue = {
            type: "video",
            id: value
          };
        } else {
          dynamicValue = {
            type: "data-field",
            id: value.id
          };
        }

        if (associationField) {
          dynamicValue['association-field-id'] = associationField.id;
        }

        var edit = this.editorService.prepareEdit(this.model, {
          'preferences.dynamic-value': dynamicValue
        });
        this.editorService.addModifications(Ember.A([edit]));
        this.set('selectingValue', false);
      },
      removeValue: function removeValue() {
        var edit = this.editorService.prepareEdit(this.model, {
          'preferences.dynamic-value': null
        });
        this.editorService.addModifications(Ember.A([edit]));
      }
    }
  });

  _exports.default = _default;
});