define("lightway-connect/templates/components/content-editor/checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HkpA5OwX",
    "block": "{\"symbols\":[\"Field\",\"@model\",\"&default\"],\"statements\":[[5,\"ui-form/field\",[],[[\"@model\",\"@property\",\"@value\"],[[23,2,[]],[23,0,[\"propertyPath\"]],[23,0,[\"value\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[6,[23,1,[\"checkBox\"]],[],[[\"@checked\",\"@toggleAction\"],[[28,\"mut\",[[28,\"get\",[[23,2,[]],[23,0,[\"propertyPath\"]]],null]],null],[28,\"action\",[[23,0,[]],[23,0,[\"updateProperty\"]]],null]]]],[0,\"\\n\\n  \"],[6,[23,1,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"label\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[14,3],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/checkbox-input.hbs"
    }
  });

  _exports.default = _default;
});