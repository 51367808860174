define("lightway-connect/components/ui-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showValidations: false,
    inputId: Ember.computed({
      get: function get() {
        return Ember.guidFor(this) + '-inpudid';
      }
    }),
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      this.set('showValidations', true);
    }
  });

  _exports.default = _default;
});