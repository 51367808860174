define("lightway-connect/components/responsive-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click() {
      this.menu.set('isActive', false);
    }
  });

  _exports.default = _default;
});