define("lightway-connect/templates/components/content-editor/remove-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3aa31YbQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isShowingModal\"]]],null,{\"statements\":[[0,\"\\n  \"],[5,\"modal-dialog\",[],[[\"@containerClass\",\"@overlayClass\",\"@onClose\"],[\"confirm-modal\",\"modal-bg-overlay\",[28,\"action\",[[23,0,[]],\"hideModal\"],null]]],{\"statements\":[[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center confirm-dialog\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"cell small-12 confirm-message\"],[8],[0,\"\\n        Are you sure you want to permanently remove this part?\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell shrink confirm-button\"],[3,\"action\",[[23,0,[]],\"confirmPartRemovalAction\"]],[8],[0,\"\\n        Confirm\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell shrink cancel-button\"],[3,\"action\",[[23,0,[]],\"hideModal\"]],[8],[0,\"\\n        Cancel\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell auto remove-part\"],[3,\"action\",[[23,0,[]],\"removePart\",[23,0,[\"part\"]]]],[8],[0,\"\\n    Remove\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/remove-part.hbs"
    }
  });

  _exports.default = _default;
});