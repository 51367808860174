define("lightway-connect/components/accounts/sign-in-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    authenticator: 'authenticator:custom',
    invitationToken: null,
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password');
        this.session.authenticate(this.authenticator, credentials).then(function () {
          Ember.run(function () {
            _this.currentAccount.load().then(function () {
              _this.flashMessages.clearMessages().success('Signed in');

              _this.router.transitionTo('index');
            }).catch(function () {//
            });
          });
        }).catch(function () {
          Ember.run(function () {
            _this.flashMessages.clearMessages().alert('There was an error');

            _this.set('errorMessage', "Invalid email or password");
          });
        });
      }
    }
  });

  _exports.default = _default;
});