define("lightway-connect/components/form-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    button: null,
    showNewActionField: null,
    newAction: null,
    selectingPageForRedirect: null,
    resetNewActionField: function resetNewActionField() {
      this.set('showNewActopmField', false);
      this.set('currentButtonIndex', null);
    },
    actions: {
      addAction: function addAction() {
        this.set('showNewActionField', true);
        this.set('newAction', {});
      },
      createAction: function createAction() {
        var actions = Ember.get(this.button, 'actions');
        actions.pushObject(this.newAction);
        this.resetNewActionField();
      },
      toggleModalForPageForRedirect: function toggleModalForPageForRedirect() {
        this.toggleProperty('selectingPageForRedirect');
      },
      selectPageForRedirect: function selectPageForRedirect(page) {
        Ember.set(this.newAction, 'page-id', page.id);
        this.toggleProperty('selectingPageForRedirect');
      },
      cancelPageSelection: function cancelPageSelection() {
        this.set('selectingPageForRedirect', false);
      }
    }
  }); //


  _exports.default = _default;
});