define("lightway-connect/components/loader-cube-transition", ["exports", "ember-cli-loaders/components/loader-cube-transition"], function (_exports, _loaderCubeTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderCubeTransition.default;
    }
  });
});