define("lightway-connect/controllers/sites/site/settings/mailing-lists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    mailingLists: Ember.computed('model', function () {
      var mailingLists = Ember.A([]);
      this.model.forEach(function (p) {
        if (!p.parent) {
          mailingLists.pushObject(p);
        }
      });
      return mailingLists;
    }),
    actions: {
      save: function save(mailingList) {
        this.target.send('save', mailingList);
      },
      delete: function _delete(mailingList) {
        var _this = this;

        mailingList.destroyRecord().then(function () {
          _this.flashMessages.clearMessages().success('Mailing List deleted');
        });
      }
    }
  });

  _exports.default = _default;
});