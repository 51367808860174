define("lightway-connect/templates/components/tags-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gXGjfBSx",
    "block": "{\"symbols\":[\"tag\",\"@model\",\"&default\"],\"statements\":[[5,\"tag-input\",[],[[\"@tags\",\"@addTag\",\"@removeTagAtIndex\",\"@placeholder\",\"@allowSpacesInTags\",\"@allowDuplicates\"],[[23,2,[\"dataTags\"]],[28,\"action\",[[23,0,[]],\"addTag\"],null],[28,\"action\",[[23,0,[]],\"removeTagAtIndex\"],null],\"Enter tag\",true,false]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tagInput-value\"],[8],[0,\"\\n    \"],[1,[23,1,[\"value\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[14,3],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/tags-component.hbs"
    }
  });

  _exports.default = _default;
});