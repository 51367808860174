define("lightway-connect/templates/sites/site/mailing-lists/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "991bSsi4",
    "block": "{\"symbols\":[\"changesetObj\",\"Form\",\"Field\"],\"statements\":[[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    New Mailing List\\n  \"],[9],[0,\"\\n\\n\"],[4,\"with\",[[28,\"changeset\",[[23,0,[\"model\"]],[23,0,[\"mailingListValidations\"]]],null]],null,{\"statements\":[[0,\"\\n    \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"save\",[23,1,[]]],null],[23,1,[]]]],{\"statements\":[[0,\"\\n\\n      \"],[6,[23,2,[\"field\"]],[],[[\"@property\"],[\"name\"]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n          Mailing List Name\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[6,[23,3,[\"textInput\"]],[],[[],[]]],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n\\n      \"],[6,[23,2,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Create Mailing List\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n    \"]],\"parameters\":[2]}],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/mailing-lists/new.hbs"
    }
  });

  _exports.default = _default;
});