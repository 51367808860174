define("lightway-connect/controllers/sites/site/settings/data-kinds/new", ["exports", "lightway-connect/validations/data/data-kind"], function (_exports, _dataKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dataKind.default, {
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    generateIdentifier: function generateIdentifier(changeset) {
      if (changeset.get('name')) {
        var identifier = changeset.get('name').replace("'", "").replace(/[_\W]+/g, "-").dasherize();
        changeset.set('identifier', identifier);
      }
    },
    actions: {
      save: function save(changeset) {
        this.generateIdentifier(changeset);
        this.target.send('save', changeset);
      }
    }
  });

  _exports.default = _default;
});