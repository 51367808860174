define("lightway-connect/templates/components/content-editor/text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HaZOZE6X",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"for\",[23,0,[\"inputId\"]]],[10,\"class\",\"cell small-10\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showValidations\"]]],null,{\"statements\":[[4,\"each\",[[28,\"get\",[[23,0,[\"model\",\"errors\"]],[23,0,[\"propertyPath\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"\\n      \"],[1,[23,1,[\"message\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"input\",[[12,\"placeholder\",[23,0,[\"placeholder\"]]],[12,\"onchange\",[28,\"action\",[[23,0,[]],\"updateProperty\"],[[\"value\"],[\"target.value\"]]]]],[[\"@id\",\"@value\",\"@size\",\"@maxlength\"],[[23,0,[\"inputId\"]],[28,\"readonly\",[[28,\"get\",[[23,0,[\"model\"]],[23,0,[\"propertyPath\"]]],null]],null],[23,0,[\"size\"]],[23,0,[\"maxlength\"]]]]],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/text-input.hbs"
    }
  });

  _exports.default = _default;
});