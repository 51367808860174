define("lightway-connect/components/responsive-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSite: Ember.inject.service(),
    // Menu closed by default
    isActive: false,
    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isActive');
      }
    }
  });

  _exports.default = _default;
});