define("lightway-connect/components/content-editor/exclusion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    name: Ember.computed('type', 'exclusion.id', function () {
      var exclusionRecord = this.store.peekRecord(this.type, this.exclusion.id);
      return exclusionRecord.name;
    })
  });

  _exports.default = _default;
});