define("lightway-connect/templates/sites/site/settings/contributors/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EABKNW9K",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-container full\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"top-bar section-head\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar-left\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"\\n        Contributor Roles\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar-right\"],[8],[0,\"\\n      \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"roles\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"ul\",true],[10,\"class\",\"menu section-menu\"],[8],[0,\"\\n          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[5,\"link-to\",[[12,\"class\",\"new-role\"]],[[\"@route\"],[\"sites.site.settings.contributors.roles.new\"]],{\"statements\":[[0,\"\\n              New Role\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"roles\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-margin-x small-up-2 medium-up-2\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"roles\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"card category-card role\"],[8],[0,\"\\n              \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.settings.contributors.roles.role\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n                \"],[1,[23,1,[\"name\"]],false],[0,\"\\n              \"]],\"parameters\":[]}],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/contributors/index.hbs"
    }
  });

  _exports.default = _default;
});