define("lightway-connect/components/sortable-list-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    sortedListManager: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('itemSorting', ['rowOrder:asc']);
    },
    itemsSorted: Ember.computed.sort('items', 'itemSorting'),
    itemsSortedBound: Ember.computed('itemsSorted.@each', function () {
      var items = this.itemsSorted.slice();
      items.__parent__ = this;
      return items;
    }),
    actions: {
      dragEnd: function dragEnd(_ref) {
        var _this = this;

        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;
        this.sortedListManager.reorderItems(sourceList, sourceIndex, targetList, targetIndex, 'items');
        sourceList.forEach(function (item) {
          if (item.hasDirtyAttributes) {
            _this.saveItemAction(item);
          }
        });
      }
    }
  });

  _exports.default = _default;
});