define("lightway-connect/templates/components/ui-form/check-box-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mhVxc62P",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"option\"],[[28,\"component\",[\"ui-form/check-box-with-label\"],[[\"model\",\"optionId\",\"property\",\"propertyValues\",\"value\",\"toggleAction\"],[[23,1,[]],[23,0,[\"optionId\"]],[23,0,[\"property\"]],[28,\"get\",[[23,0,[\"model\"]],[23,0,[\"property\"]]],null],[23,0,[\"value\"]],[28,\"action\",[[23,0,[]],\"toggleCheckbox\"],null]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/check-box-group.hbs"
    }
  });

  _exports.default = _default;
});