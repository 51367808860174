define("lightway-connect/components/accounts/change-email-form", ["exports", "fetch", "lightway-connect/validations/accounts/change-email-form"], function (_exports, _fetch, _changeEmailForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_changeEmailForm.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formIsHidden: false,
    hideForm: function hideForm() {
      this.set('formIsHidden', true);
    },
    actions: {
      changeEmail: function changeEmail(changesetObj) {
        var _this = this;

        changesetObj.validate().then(function () {
          if (changesetObj.get('isValid')) {
            var _this$get = _this.get('session.data.authenticated'),
                token = _this$get.token;

            if (_this.get('session.isAuthenticated') && token) {
              var authorizationToken = "Bearer ".concat(token);
              return _this.requestEmailChange(authorizationToken, changesetObj);
            } else {
              _this.flashMessages.clearMessages().alert('There was an error');

              return;
            }
          } else {
            _this.flashMessages.clearMessages().alert('There was an error');

            return;
          }
        });
      }
    },
    requestEmailChange: function requestEmailChange(authorizationToken, changesetObj) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)('/api/backend/accounts', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: authorizationToken
          },
          body: JSON.stringify({
            "data": {
              "type": "accounts",
              "attributes": {
                "new-email": changesetObj.get('new-email')
              }
            }
          })
        }).then(function (response) {
          if (response.ok) {
            Ember.run(function () {
              _this2.flashMessages.clearMessages().success('Change of email has been requested');

              _this2.hideForm();

              return resolve();
            });
          } else {
            response.json().then(function (responseJson) {
              Ember.run(function () {
                _this2.flashMessages.clearMessages().alert('There was an error');

                _this2.addErrorsToChangeset(changesetObj, responseJson);

                return reject();
              });
            });
          }
        }).catch(function () {
          Ember.run(function () {
            _this2.flashMessages.clearMessages().alert('There was an error');

            return reject();
          });
        });
      });
    },
    addErrorsToChangeset: function addErrorsToChangeset(changesetObj, reason) {
      reason.errors.forEach(function (_ref) {
        var source = _ref.source,
            detail = _ref.detail;
        var paths = source.pointer.split('/');
        var attr = paths[paths.length - 1].split('-').join(' ');

        if (attr != "base") {
          return changesetObj.pushErrors('newEmail', "Email ".concat(detail));
        } else {
          changesetObj.pushErrors('newEmail', "".concat(detail));
        }
      });
    }
  });

  _exports.default = _default;
});