define("lightway-connect/helpers/data-property-for-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dataPropertyForField(params
  /*, hash*/
  ) {
    var field = params[0];
    var item = params[1];
    var store = params[2];
    var property; // Find property match for field and item

    item.properties.forEach(function (prop) {
      if (prop.field === field) {
        property = prop;
      }
    }); // Create new property if no match

    if (!property) {
      property = store.createRecord('data-property', {
        item: item,
        field: field
      });
    }

    return property;
  });

  _exports.default = _default;
});