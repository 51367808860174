define("lightway-connect/templates/sites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBDFVOfW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"header\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites.hbs"
    }
  });

  _exports.default = _default;
});