define("lightway-connect/templates/sites/site/settings/domains/domain/subdomains/subdomain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9AauE4wv",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/domains/domain/subdomains/subdomain.hbs"
    }
  });

  _exports.default = _default;
});