define("lightway-connect/templates/components/checkbox-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vnDED+ZA",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"classForPropertyName\"]],\" \",[23,0,[\"withErrorIfError\"]]]]],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-justify\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@name\",\"@id\",\"@checked\",\"@action\"],[\"checkbox\",[23,0,[\"propertyName\"]],[23,0,[\"inputId\"]],[28,\"mut\",[[28,\"get\",[[23,0,[\"changeset\"]],[23,0,[\"propertyName\"]]],null]],null],[23,0,[\"toggleAction\"]]]]],[0,\"\\n\\n      \"],[7,\"label\",true],[11,\"for\",[23,0,[\"inputId\"]]],[8],[0,\"\\n        \"],[1,[22,\"label\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"changeset\",\"errors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"errors\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"changeset\",\"errors\"]]],null,{\"statements\":[[0,\"        \"],[5,\"changeset-error\",[],[[\"@error\",\"@propertyName\"],[[23,1,[]],[23,0,[\"propertyName\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/checkbox-with-label.hbs"
    }
  });

  _exports.default = _default;
});