define("lightway-connect/templates/components/add-object-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O5BhJSBj",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"field\",\"createAction\"],[[28,\"component\",[\"ui-form/field\"],[[\"model\"],[[23,0,[\"obj\"]]]]],[28,\"action\",[[23,0,[]],[23,0,[\"createAction\"]],[23,1,[]],[23,0,[\"obj\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/add-object-to-array.hbs"
    }
  });

  _exports.default = _default;
});