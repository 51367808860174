define("lightway-connect/components/content-editor/text-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model) {
    return Ember.computed(key, "model.".concat(key), function () {
      var result;
      var reference = model.get(key);

      if (reference) {
        this.editorService.canvasVersion.globalStyle.globalFonts.forEach(function (font) {
          if (font.id == reference) {
            result = font;
          }
        });
      }

      return result;
    });
  }

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments); // Set value to computed property with dynamic dependent key


      Ember.defineProperty(this, 'currentFont', valueFromProperty("preferences." + this.editorService.viewport.size + "." + this.property + "-font-reference", this.model));
    }
  });

  _exports.default = _default;
});