define("lightway-connect/components/sites/parts/sidebars/pending-image-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B45vNsoI",
    "block": "{\"symbols\":[\"folder\",\"image\"],\"statements\":[[4,\"if\",[[24,[\"selectedFolder\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"selectedFolder\",\"name\"]],false],[0,\"\\n\\n  \"],[1,[28,\"sites/media/upload-image-form\",null,[[\"folder\"],[[24,[\"selectedFolder\"]]]]],false],[0,\"\\n\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"selectedFolder\",\"images\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"div\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectImage\",[23,2,[]]]],[8],[0,\"\\n            \"],[1,[23,2,[\"name\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    Select a folder:\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"image-folders\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"folders\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"imageFolders\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[7,\"div\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectFolder\",[23,1,[]]]],[8],[0,\"\\n              \"],[1,[23,1,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/sidebars/pending-image-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});