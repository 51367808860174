define("lightway-connect/components/content-editor/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    actions: {
      add: function add(dataTag) {
        var modifications = Ember.A([]);

        if (!dataTag.id) {
          var addHash = this.editorService.prepareAdd(dataTag);
          modifications.addObject(addHash);
        } // Collect model's existing dataTags


        var dTags = Ember.A([]);
        this.model.dataTags.forEach(function (dTag) {
          dTags.addObject(dTag);
        }); // Associate new tag with model

        dTags.addObject(dataTag);
        var editHash = this.editorService.prepareEdit(this.model, {
          dataTags: dTags
        });
        modifications.addObject(editHash);
        this.editorService.addModifications(modifications);
      },
      removeAt: function removeAt(index) {
        var tags = Ember.A([]);
        this.model.dataTags.forEach(function (dataTag) {
          tags.addObject(dataTag);
        });
        tags.removeAt(index);
        var editHash = this.editorService.prepareEdit(this.model, {
          dataTags: tags
        });
        this.editorService.addModifications(Ember.A([editHash]));
      }
    }
  });

  _exports.default = _default;
});