define("lightway-connect/templates/components/accounts/sign-in-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VByjBFJl",
    "block": "{\"symbols\":[\"Form\",\"Field\",\"Field\",\"&default\"],\"statements\":[[5,\"ui-form\",[],[[\"@model\",\"@onSubmit\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"authenticate\"],null]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"errorMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error-message\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"error\"],[8],[0,\"\\n        \"],[1,[23,0,[\"errorMessage\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,[23,1,[\"field\"]],[],[[\"@property\"],[\"identification\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Email\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,3,[\"emailInput\"]],[[12,\"autocomplete\",\"username\"]],[[],[]]],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\\n  \"],[6,[23,1,[\"field\"]],[],[[\"@property\"],[\"password\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Password\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"passwordInput\"]],[[12,\"autocomplete\",\"current-password\"]],[[],[]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-justify\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-6\"],[8],[0,\"\\n      \"],[6,[23,1,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Sign In\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-6 reset-password\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"reset-password\"]],{\"statements\":[[0,\"\\n        Forgot password?\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/accounts/sign-in-form.hbs"
    }
  });

  _exports.default = _default;
});