define("lightway-connect/components/content-editor/parts/password-field-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label>
    Password
  </label>
  
  <input>
  
  {{yield}}
  
  */
  {
    id: "AgAi16Ys",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\",true],[8],[0,\"\\n  Password\\n\"],[9],[0,\"\\n\\n\"],[7,\"input\",true],[8],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/password-field-part.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});