define("lightway-connect/components/ui-form/multi-select-check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    valueMatches: Ember.computed('arrayValues', 'value', {
      get: function get() {
        if (this._valueMatches) {
          return this._valueMatches;
        }

        if (this.arrayValues) {
          return this.arrayValues.includes(this.value);
        }

        return null;
      },
      set: function set(key, value) {
        return this._valueMatches = value;
      }
    })
  });

  _exports.default = _default;
});