define("lightway-connect/controllers/sites/site/settings/domains/new", ["exports", "lightway-connect/validations/sites/domain"], function (_exports, _domain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_domain.default, {
    store: Ember.inject.service(),
    actions: {
      saveDomain: function saveDomain(changeset) {
        this.target.send('saveDomain', changeset);
      }
    }
  });

  _exports.default = _default;
});