define("lightway-connect/templates/sites/site/media/image-folders/image-folder/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mY2HAxVJ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"img\",true],[11,\"src\",[23,1,[\"imageUrl\"]]],[11,\"alt\",[23,1,[\"name\"]]],[8],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.image.rename\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Rename\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.image.move\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Move to Folder\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.image-folders.image-folder.image.delete\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n      Delete\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/image-folders/image-folder/image.hbs"
    }
  });

  _exports.default = _default;
});