define("lightway-connect/controllers/sites/site/settings/user-kinds/user-kind/index", ["exports", "lightway-connect/validations/user-kind"], function (_exports, _userKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userKind.default, {
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    actions: {
      save: function save(changeset) {
        this.target.send('saveUserKind', changeset);
      },
      saveField: function saveField(changeset) {
        this.target.send('saveField', changeset);
      },
      setAsDefault: function setAsDefault(field) {
        this.model.userKind.set('defaultField', field);
        this.model.userKind.save();
      },
      deleteField: function deleteField(field) {
        var _this = this;

        var fieldParent = field.parent;
        field.destroyRecord().then(function () {
          var siblingsList;

          if (fieldParent) {
            siblingsList = fieldParent.fields;
          } // Decrease position of remaining fields only if others remain


          if (siblingsList) {
            siblingsList.forEach(function (field) {
              field.set('rowOrder', field.rowOrder - 1);

              if (field.hasDirtyAttributes) {
                field.save();
              }
            });
          }

          _this.flashMessages.clearMessages().success('Field removed');
        });
      }
    }
  });

  _exports.default = _default;
});