define("lightway-connect/components/loader-ball-scale-ripple", ["exports", "ember-cli-loaders/components/loader-ball-scale-ripple"], function (_exports, _loaderBallScaleRipple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderBallScaleRipple.default;
    }
  });
});