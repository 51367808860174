define("lightway-connect/templates/components/ui-form/check-box-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d1DxxuGD",
    "block": "{\"symbols\":[\"Field\",\"@model\",\"&default\"],\"statements\":[[5,\"ui-form/field\",[],[[\"@model\",\"@property\",\"@optionId\",\"@value\"],[[23,2,[]],[23,0,[\"property\"]],[23,0,[\"optionId\"]],[23,0,[\"value\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[6,[23,1,[\"checkBox\"]],[],[[\"@checked\",\"@toggleAction\"],[[23,0,[\"isChecked\"]],[23,0,[\"toggleAction\"]]]]],[0,\"\\n\\n  \"],[6,[23,1,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"label\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[14,3],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/check-box-with-label.hbs"
    }
  });

  _exports.default = _default;
});