define("lightway-connect/templates/components/layouts/section-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BpiNlZqc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12 medium-2 medium-cell-block-y section-bar\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-4 large-2\"],[8],[0,\"\\n\\n    \"],[14,1],[0,\"\\n    \\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/layouts/section-bar.hbs"
    }
  });

  _exports.default = _default;
});