define("lightway-connect/templates/sites/site/design/layouts/layout/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABm7sPAg",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell auto cell-block-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x small-12\"],[8],[0,\"\\n    \"],[5,\"content-editor\",[],[[\"@model\",\"@tools\",\"@saveAction\"],[[23,1,[]],[23,0,[\"editorTools\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/design/layouts/layout/index.hbs"
    }
  });

  _exports.default = _default;
});