define("lightway-connect/components/quill-editor", ["exports", "quill", "lodash"], function (_exports, _quill, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['quill-editor'],
    defaultTag: null,
    editor: null,
    textChange: function textChange() {},
    selectionChange: function selectionChange() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var options = {
        modules: {
          toolbar: this.toolbarOptions
        },
        placeholder: 'Enter text here...',
        theme: this.theme
      }; // var Block
      // if (this.defaultTag) {
      //   Block = Quill.import('blots/block');
      //   Block.tagName = this.defaultTag;
      //   Quill.register(Block, true);
      // } else {
      //   Block = Quill.import('blots/block');
      //   Block.tagName = "p";
      //   Quill.register(Block, true);
      // }

      var Block = _quill.default.import('blots/block');

      Block.tagName = this.defaultTag || "p";

      _quill.default.register(Block, true);

      var editor = new _quill.default(this.element, options);
      editor.on("text-change", function (delta, oldDelta, source) {
        _this.textChange(_this.editor, delta, oldDelta, source);
      });
      editor.on("selection-change", function (delta, oldDelta, source) {
        _this.selectionChange(_this.editor, delta, oldDelta, source);
      });
      editor.setContents(this.value);
      editor.enable(this.enabled);
      this.set("editor", editor);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.value == null) {
        this.editor.setContents(null);
      } else {
        if (!(0, _lodash.isEqual)(this.value.ops, this.editor.editor.delta.ops)) {
          this.editor.setContents(this.value);
        }
      }
    }
  });

  _exports.default = _default;
});