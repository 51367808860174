define("lightway-connect/templates/components/sortable-list-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WoE6wAUu",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[5,\"drag-sort-list\",[],[[\"@items\",\"@group\",\"@dragEndAction\",\"@class\",\"@childTagName\",\"@childClass\",\"@handle\"],[[23,0,[\"itemsSortedBound\"]],\"items\",[28,\"action\",[[23,0,[]],\"dragEnd\"],null],\"grid-x\",\"li\",\"cell small-12\",\".move-item\"]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"cell auto name\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"itemRoute\"]]],null,{\"statements\":[[0,\"        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,0,[\"itemRoute\"]],[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"span\",true],[10,\"class\",\"cell shrink move-item\"],[10,\"draggable\",\"true\"],[8],[0,\"\\n      Move\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"deleteRoute\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"cell shrink delete-item\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,0,[\"deleteRoute\"]],[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n          Delete\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"pages\"]]],null,{\"statements\":[[0,\"    \"],[5,\"sortable-list-menu\",[],[[\"@class\",\"@navRoute\",\"@itemRoute\",\"@itemKind\",\"@items\",\"@saveItemAction\"],[\"grid-x resource-list\",\"sites.site.content.pages\",\"sites.site.content.pages.page\",\"pages\",[23,1,[\"pages\"]],[23,0,[\"saveItemAction\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/sortable-list-menu.hbs"
    }
  });

  _exports.default = _default;
});