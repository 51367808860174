define("lightway-connect/components/content-editor/parts/text-field-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label>
    {{#if this.attrs.part.preferences.label}}
      {{this.attrs.part.preferences.label}}
    {{else}}
      Field
    {{/if}}
  </label>
  
  <input>
  
  {{yield}}
  
  */
  {
    id: "6IuRyIxz",
    block: "{\"symbols\":[\"@part\",\"&default\"],\"statements\":[[7,\"label\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"preferences\",\"label\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[\"preferences\",\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Field\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[7,\"input\",true],[8],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/text-field-part.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});