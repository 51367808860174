define("lightway-connect/components/resources-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('itemToDelete', null);
    },
    statusClasses: Ember.computed('item.{status,enabled}', function () {
      var classes = "status-indicator";

      if (this.item.isA('page')) {
        if (this.item.status === "published") {
          classes += " enabled";
        } else {
          classes += " disabled";
        }
      } else {
        if (this.item.enabled) {
          classes += " enabled";
        } else {
          classes += " disabled";
        }
      }

      return classes;
    }),
    actions: {
      closeDeleteModal: function closeDeleteModal() {
        this.set('itemToDelete', null);
      },
      prepareItemForDeletion: function prepareItemForDeletion(item) {
        this.set('itemToDelete', item);
      },
      confirmDeletion: function confirmDeletion() {
        this.deleteAction(this.itemToDelete);
        this.set('itemToDelete', null);
      },
      toggleStatus: function toggleStatus() {
        if (this.item.isA('page')) {
          if (this.item.status === "published") {
            this.item.set('status', 'unpublished');
            this.item.save();
          } else {
            this.item.set('status', 'published');
            this.item.save();
          }
        } else {
          this.item.toggleProperty('enabled');
          this.item.save();
        }
      }
    }
  });

  _exports.default = _default;
});