define("lightway-connect/controllers/sites/site/web-forms/web-form/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    actions: {
      saveWebForm: function saveWebForm(changeset) {
        this.target.send('saveWebForm', changeset);
      }
    }
  });

  _exports.default = _default;
});