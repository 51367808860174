define("lightway-connect/components/content-editor/conditional-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function matchValues(key, model, valueToMatch) {
    return Ember.computed(key, "model.".concat(key), function () {
      return Ember.get(model, key) === valueToMatch;
    });
  }

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'conditionMatches', matchValues(this.propertyPath, this.model, this.valueToMatch));
    },
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    })
  });

  _exports.default = _default;
});