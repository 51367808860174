define("lightway-connect/components/select-option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkIfValueMatches();
    },
    checkIfValueMatches: function checkIfValueMatches() {
      var result = this.valueToMatch == this.value;
      this.set('optionSelected', result);
    }
  });

  _exports.default = _default;
});