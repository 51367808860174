define("lightway-connect/mixins/cascade-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var recordsToUnload = [];
      var hasManyRecordsArray = []; // collect all records to unload into recordsToUnload variable

      snapshot.record.eachRelationship(function (name, descriptor) {
        var options = descriptor.options,
            kind = descriptor.kind;
        var relationshipName = descriptor.key;

        if (options.cascadeDelete && kind === 'hasMany') {
          var hasManyRecords = snapshot.record.hasMany(relationshipName).value();

          if (hasManyRecords !== null) {
            hasManyRecordsArray = hasManyRecords.toArray();
          }

          recordsToUnload = recordsToUnload.concat(hasManyRecordsArray);
        }

        if (options.cascadeDelete && kind === 'belongsTo') {
          var belongsToRecords = snapshot.record.belongsTo(relationshipName).value();
          recordsToUnload = recordsToUnload.concat([belongsToRecords]);
        }
      });
      return this._super.apply(this, arguments).then(function (response) {
        recordsToUnload.forEach(function (childRecord) {
          store.unloadRecord(childRecord);
        });
        return response;
      });
    }
  });

  _exports.default = _default;
});