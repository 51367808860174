define("lightway-connect/components/sites/parts/sidebars/container-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedElement: Ember.inject.service(),
    actions: {
      removePart: function removePart() {
        var part = this.get('selectedElement.element');
        var parent = part.get('parent');
        parent.get('removedParts').pushObject(part);
        part.set('parent', null);
        this.selectedElement.deselect();
        this.flashMessages.clearMessages().success('Content removed');
      }
    }
  });

  _exports.default = _default;
});