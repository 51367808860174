define("lightway-connect/components/sites/media/upload-image-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uuU2ThfY",
    "block": "{\"symbols\":[\"dropzone\",\"queue\"],\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[\"images\"]],{\"statements\":[[4,\"if\",[[23,1,[\"active\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"valid\"]]],null,{\"statements\":[[0,\"      Drop to upload\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Invalid\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"    Uploading \"],[1,[23,2,[\"files\",\"length\"]],false],[0,\" files. (\"],[1,[23,2,[\"progress\"]],false],[0,\"%)\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\",true],[8],[0,\"Upload Images\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"supported\"]]],null,{\"statements\":[],\"parameters\":[]},null],[4,\"file-upload\",null,[[\"name\",\"accept\",\"multiple\",\"onfileadd\"],[\"images\",\"image/*\",true,[28,\"action\",[[23,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"id\",\"upload-image\"],[10,\"tabindex\",\"0\"],[8],[0,\"Add an Image.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/media/upload-image-form/template.hbs"
    }
  });

  _exports.default = _default;
});