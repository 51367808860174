define("lightway-connect/components/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: Ember.computed('status', function () {
      switch (this.status) {
        case 'pending':
          return 'Pending';

        case 'complete':
          return 'Completed';

        case 'expired':
          return 'Expired';

        case 'unpaid':
          return 'Unpaid';

        case 'paid':
          return 'Paid';

        case 'partially-paid':
          return 'Partially Paid';

        case 'partially-refunded':
          return 'Partially Refunded';

        case 'refunded':
          return 'Refunded';

        case 'active':
          return 'Active';

        case 'inactive':
          return 'Inactive';

        case 'cancelled':
          return 'Cancelled';

        case 'sent':
          return 'Sent';

        case 'pending_payment_method_confirmation':
          return 'Pending Confirmation of Payment Method';
      }

      return this.status;
    })
  });

  _exports.default = _default;
});