define("lightway-connect/components/sites/parts/add-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hb3tz8c5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"addContainer\",[24,[\"pnt\"]]]],[8],[0,\"\\n  Add Container\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/add-container/template.hbs"
    }
  });

  _exports.default = _default;
});