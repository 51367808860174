define("lightway-connect/components/sites/data/items/item-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    addingImage: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.definePropertyKind();
      this.initializeProperty();
    },
    definePropertyKind: function definePropertyKind() {
      if (this.kind == 'text-field') {
        this.set('textField', true);
      } else if (this.kind == 'email-field') {
        this.set('emailField', true);
      } else if (this.kind == 'phone-field') {
        this.set('phoneField', true);
      } else if (this.kind == 'extended-text-field') {
        this.set('extendedTextField', true);
      } else if (this.kind == 'date-field') {
        this.set('dateField', true);
      } else if (this.kind == 'time-field') {
        this.set('timeField', true);
      } else if (this.kind == 'image-field') {
        this.set('imageField', true);
      } else if (this.kind == 'link-field') {
        this.set('linkField', true);
      } else if (this.kind == 'association-field') {
        this.set('associationField', true);
      } else if (this.kind == 'image-collection-field') {
        this.set('imageCollectionField', true);
      }
    },
    initializeProperty: function initializeProperty() {
      var property;
      var self = this;

      if (this.newRecord == true) {
        property = this.store.createRecord('data-property', {
          item: this.item,
          field: this.field
        });
      } else {
        if (self.item) {
          var propertyExists = false;
          self.item.get('properties').forEach(function (prop) {
            if (prop.get('field.id') == self.field.id) {
              property = prop;
              propertyExists = true;
            }
          });

          if (!propertyExists) {
            property = self.get('store').createRecord('data-property', {
              item: self.item,
              field: self.field
            });
          }
        }
      }

      self.set('property', property);
    },
    actions: {
      updateValue: function updateValue(val) {
        this.set('property.value', val);
      },
      addImage: function addImage() {
        this.set('addingImage', true);
      },
      showTranslations: function showTranslations() {
        this.set('translationsVisible', true);
      },
      hideTranslations: function hideTranslations() {
        this.set('translationsVisible', false);
      }
    }
  });

  _exports.default = _default;
});