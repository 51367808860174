define("lightway-connect/components/content-editor/properties/data-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-canvas-settings'],
    store: Ember.inject.service(),
    actions: {
      save: function save(item) {
        item.save();
      }
    }
  });

  _exports.default = _default;
});