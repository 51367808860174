define("lightway-connect/components/content-editor/properties/list-item-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="cell small-12 current-part-settings">
  
    <ContentEditor::SettingPanel
      @saveAction={{@saveAction}}
      @model={{@model}}
      @isOpen={{true}}
      class="settings-panel properties" as |Panel|>
  
      <Panel.header>
        List Item Container Properties
      </Panel.header>
  
      <Panel.body>
      </Panel.body>
    </ContentEditor::SettingPanel>
  
  </div>
  */
  {
    id: "eKpryGeW",
    block: "{\"symbols\":[\"Panel\",\"@saveAction\",\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12 current-part-settings\"],[8],[0,\"\\n\\n  \"],[5,\"content-editor/setting-panel\",[[12,\"class\",\"settings-panel properties\"]],[[\"@saveAction\",\"@model\",\"@isOpen\"],[[23,2,[]],[23,3,[]],true]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n      List Item Container Properties\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/properties/list-item-container.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});