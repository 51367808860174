define("lightway-connect/components/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ModalDialog @overlayClass="loader-bg-overlay grid-y align-center" @containerClass="file-selector-modal cell shrink" @onClose={{null}}>
  
    <div class="grid-x align-center">
      <div class="cell shrink">
        <LoaderBallPulse />
  
        {{yield}}
      </div>
    </div>
  
  </ModalDialog>
  
  */
  {
    id: "1jTTfppP",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[5,\"modal-dialog\",[],[[\"@overlayClass\",\"@containerClass\",\"@onClose\"],[\"loader-bg-overlay grid-y align-center\",\"file-selector-modal cell shrink\",null]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n      \"],[5,\"loader-ball-pulse\",[],[[],[]]],[0,\"\\n\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/loader.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});