define("lightway-connect/components/if-style-not-associated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    notAssociated: Ember.computed('part.styles', function () {
      return !this.part.get('styles').includes(this.style);
    })
  });

  _exports.default = _default;
});