define("lightway-connect/templates/sites/site/media/document-folders/document-folder/document/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/4fRUro",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Delete Document\"],[9],[0,\"\\n\\nAre you sure you want to permanently delete this document?\\n\\n\"],[7,\"button\",false],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"destroyDocument\"]],[8],[0,\"\\n  Delete Document\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/document-folders/document-folder/document/delete.hbs"
    }
  });

  _exports.default = _default;
});