define("lightway-connect/components/paginated-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    flashMessages: Ember.inject.service(),
    totalRecords: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      return this.items.meta.pagination.records;
    }),
    previousNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.prev) {
        return;
      }

      return this.items.meta.pagination.prev.number;
    }),
    nextNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.next) {
        return;
      }

      return this.items.meta.pagination.next.number;
    }),
    lastNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.last) {
        return;
      }

      return this.items.meta.pagination.last.number || this.previousNumber + 1;
    }),
    currentNumber: Ember.computed('items', function () {
      if (!this.items) {
        return;
      }

      if (!this.items.meta) {
        return;
      }

      if (!this.items.meta.pagination) {
        return;
      }

      if (!this.items.meta.pagination.current) {
        return;
      }

      return this.items.meta.pagination.current.number;
    }),
    pageNumbers: Ember.computed('items', 'lastNumber', function () {
      if (!this.nextNumber && !this.previousNumber) {
        return Ember.A([]);
      }

      var total = 1;

      if (this.lastNumber) {
        total = this.lastNumber;
      }

      return new Array(total + 1).join('x').split('').map(function (e, i) {
        return i + 1;
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.queryKey) {
        this.set('queryKey', "page");
      }

      this.checkForMultiplePages();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.checkForMultiplePages();
    },
    checkForMultiplePages: function checkForMultiplePages() {
      if (this.items && this.items.meta && this.items.meta.pagination) {
        // let hasSelfTag = this.items.meta.pagination.self && this.items.meta.pagination.self.number > 1
        var hasNextTag = false; // if (this.items.meta.pagination.next) {
        //   hasNextTag = this.items.meta.pagination && Object.keys(this.items.meta.pagination.next).length > 0
        // }

        if (this.items.meta.pagination.next) {
          hasNextTag = true;
        }

        var hasPreviousTag = false;

        if (this.items.meta.pagination.prev) {
          // hasPreviousTag = true
          hasPreviousTag = this.items.meta.pagination && Object.keys(this.items.meta.pagination.prev).length > 0;
        }

        if (this.items.meta) // this.set('moreThanOnePage', (hasSelfTag || hasNextTag))
          // TODO: Fix this
          this.set('moreThanOnePage', true);
        this.set('hasNextLink', hasNextTag);
        this.set('hasPreviousLink', hasPreviousTag);
      } else {
        this.set('moreThanOnePage', false);
      }
    },
    selectionList: Ember.A([]),
    reset: function reset() {
      // Deselect all
      this.set('selectionList', Ember.A([])); // Disable moving mode

      this.set('inMovingMode', false); // Disable select model

      this.set('inSelectMode', false);
    },
    actions: {
      enableSelectMode: function enableSelectMode() {
        this.set('inSelectMode', true);
      },
      deselectAll: function deselectAll() {
        this.reset();
      },
      toggleItemSelection: function toggleItemSelection(item) {
        if (this.selectionList.includes(item)) {
          this.selectionList.removeObject(item);
        } else {
          this.selectionList.pushObject(item);
        }
      },
      moveSelected: function moveSelected() {
        this.set('inMovingMode', true);
      },
      moveToCategory: function moveToCategory(category) {
        var _this = this;

        var jobs = this.selectionList.map(function (item) {
          item.set('parent', category);
          item.save();
        });
        Promise.all(jobs).then(function () {
          _this.flashMessages.clearMessages().success('Selected items have been moved'); // Reset component state


          _this.reset();
        });
      }
    }
  });

  _exports.default = _default;
});