define("lightway-connect/components/content-editor/properties/data-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-part-settings'],
    editorService: Ember.inject.service(),
    prototypes: Ember.computed('editorService.{inPageMode,inLayoutMode,inItemMode,inEssentialContentItemMode,inKindMode}', function () {
      var prototypes = Ember.A([]);
      var prototypesParent;

      if (this.editorService.inPageMode) {
        prototypesParent = this.editorService.model.layout;
      }

      if (this.editorService.inItemMode || this.editorService.inEssentialContentItemMode) {
        prototypesParent = this.editorService.model.parent;
      }

      if (this.editorService.inKindMode) {
        prototypesParent = this.editorService.model;
      }

      if (this.editorService.inLayoutMode) {
        prototypesParent = this.editorService.model;
      }

      prototypesParent.prototypes.forEach(function (prototype) {
        if (prototype.kind == "data-collection") {
          prototypes.addObject(prototype);
        }
      });
      return prototypes;
    })
  });

  _exports.default = _default;
});