define("lightway-connect/components/content-editor/panels/background-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function backgroundImageReference(model, overrideBackground, viewport, store) {
    return Ember.computed('background-image-reference', 'overrideBackground', "model.preferences.".concat(viewport, ".background-image-reference"), "model.preferences.{small.overrides.@each,medium.overrides.@each,large.overrides.@each}", function () {
      var imageId = model.get("preferences.".concat(viewport, ".background-image-reference"));

      if (imageId) {
        return store.peekRecord('image', imageId);
      } else {
        return null;
      }
    });
  }

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    isShowingModalForBackgroundImage: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'nativeBackgroundImage', backgroundImageReference(this.model, this.overrideBackground, this.editorService.viewport.size, this.store));
    },
    overrideHash: Ember.computed('model.preferences.{small.overrides.@each,medium.overrides.@each,large.overrides.@each},editorService.viewport.size', function () {
      var _this = this;

      if (!this.model.basePrototype) {
        return null;
      }

      if (!this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides"))) {
        this.model.set("preferences.".concat(this.editorService.viewport.size, ".overrides"), Ember.A([]));
      }

      var ovr;
      this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides")).forEach(function (override) {
        if (override['part-id'] === _this.overridable['part-id']) {
          ovr = override;
        }
      });

      if (!ovr) {
        ovr = {
          'part-id': this.overridable['part-id']
        };
        this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides")).addObject(ovr);
      }

      return ovr;
    }),
    currentBackgroundImage: Ember.computed('nativeBackgroundImage', 'overrideHash', 'overrideBackgroundImage.id', 'editorService.viewport.size', function () {
      if (this.overrideBackgroundImage) {
        return this.overrideBackgroundImage;
      }

      return this.nativeBackgroundImage;
    }),
    overrideBackgroundImage: Ember.computed('overrideHash', 'overrideHash.background-image-reference', function () {
      if (!this.overrideHash) {
        return null;
      }

      var imageId = this.overrideHash['background-image-reference'];

      if (imageId) {
        return this.store.peekRecord('image', imageId);
      } else {
        return null;
      }
    }),
    actions: {
      toggleModalForBackgroundImage: function toggleModalForBackgroundImage() {
        this.toggleProperty('isShowingModalForBackgroundImage');
      },
      selectBackgroundImage: function selectBackgroundImage(image) {
        var _this2 = this;

        var edit;
        var changes = {};

        if (this.overrideHash) {
          var newOverrides = Ember.A([]);

          if (!this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides"))) {
            this.model.set("preferences.".concat(this.editorService.viewport.size, ".overrides"), Ember.A([]));
          }

          this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides")).forEach(function (override) {
            if (Ember.get(override, 'part-id') === "".concat(_this2.overrideHash['part-id'])) {
              var newOverride = {};
              Object.keys(override).forEach(function (key) {
                newOverride[key] = override[key];
              });
              newOverride['background-image-reference'] = image.id;
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes["preferences.".concat(this.editorService.viewport.size, ".overrides")] = newOverrides;
        } else {
          changes["preferences.".concat(this.editorService.viewport.size, ".background-image-reference")] = image.id;
        }

        edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.toggleProperty('isShowingModalForBackgroundImage');
      },
      removeBackgroundImage: function removeBackgroundImage() {
        var _this3 = this;

        var edit;
        var changes = {};

        if (this.overrideHash) {
          var newOverrides = Ember.A([]);
          this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides")).forEach(function (override) {
            if (Ember.get(override, 'part-id') === "".concat(_this3.overrideHash['part-id'])) {
              var newOverride = {};
              Object.keys(override).forEach(function (key) {
                if (key != "background-image-reference") {
                  newOverride[key] = override[key];
                }
              });
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes["preferences.".concat(this.editorService.viewport.size, ".overrides")] = newOverrides;
          edit = this.editorService.prepareEdit(this.model, changes);
        } else {
          changes["preferences.".concat(this.editorService.viewport.size, ".background-image-reference")] = null;
          edit = this.editorService.prepareEdit(this.model, changes);
        }

        this.editorService.addModifications(Ember.A([edit]));
      },
      toggleOverridable: function toggleOverridable(value, event) {
        var checked = event.toElement.checked;

        if (checked) {
          if (!this.modelObject.preferences.overridable) {
            this.modelObject.set('preferences.overridable', Ember.A([]));
          }

          this.modelObject.preferences.overridable.addObject(value);
        } else {
          this.modelObject.preferences.overridable.removeObject(value);
        }
      }
    }
  });

  _exports.default = _default;
});