define("lightway-connect/validations/accounts/change-password-form", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    currentPassword: (0, _validators.validatePresence)(true),
    newPassword: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8
    })] // newPasswordConfirmation: validateConfirmation({
    //   on: 'newPassword',
    //   message: 'New password confirmation does not match'
    // })

  };
  _exports.default = _default;
});