define("lightway-connect/components/content-editor/parts/video-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNameBindings: ['grid-x', 'align-center'],
    video: Ember.computed('part.kontent', function () {
      return this.part.kontent;
    })
  });

  _exports.default = _default;
});