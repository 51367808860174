define("lightway-connect/components/sites/parts/text-part/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KB7x9LGO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"elementSelected\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-part selected-part part\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"translationsVisible\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"translations-enabled\"],[3,\"action\",[[23,0,[]],\"hideTranslations\"]],[8],[0,\"\\n        Hide Translations\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"translations-disabled\"],[3,\"action\",[[23,0,[]],\"showTranslations\"]],[8],[0,\"\\n        Show Translations\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \\n    \"],[1,[28,\"textarea\",null,[[\"value\"],[[28,\"mut\",[[28,\"get\",[[24,[\"part\"]],\"textContent\"],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"translationsVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"translations-for-target\",null,[[\"targetString\",\"model\"],[\"text_content\",[24,[\"part\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-part part\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"edit-text\"],[3,\"action\",[[23,0,[]],\"editText\",[24,[\"part\"]]]],[8],[0,\"\\n      \"],[1,[24,[\"part\",\"textContent\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/text-part/template.hbs"
    }
  });

  _exports.default = _default;
});