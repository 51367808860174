define("lightway-connect/templates/sites/site/web-forms/web-form/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wtYdKWx8",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/web-forms/web-form/fields.hbs"
    }
  });

  _exports.default = _default;
});