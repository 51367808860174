define("lightway-connect/components/accounts/reset-password-form", ["exports", "fetch", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/accounts/reset-password-form"], function (_exports, _fetch, _emberChangeset, _emberChangesetValidations, _resetPasswordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resetPasswordForm.default, {
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('mod', {});
      var changeset = new _emberChangeset.default(this.mod, (0, _emberChangesetValidations.default)(_resetPasswordForm.default), _resetPasswordForm.default);
      this.set('changeset', changeset);
      this.lookupToken();
    },
    lookupToken: function lookupToken() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)("/api/backend/accounts/password/edit?reset_password_token=".concat(_this.token), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then(function (response) {
          if (response.ok) {
            Ember.run(function () {
              _this.flashMessages.clearMessages();

              _this.set('approved', true);

              resolve();
            });
          } else {
            Ember.run(function () {
              _this.set('approved', false);

              var err = "";
              response.json().then(function (responseJSON) {
                // Prepare error message for displaying
                if (responseJSON.errors) {
                  err = "Token ".concat(responseJSON.errors[0].detail);
                } else {
                  err = "There was an error";
                }

                _this.flashMessages.clearMessages().alert(err);

                _this.router.transitionTo('reset-password');

                reject();
              });
            });
          }
        }).catch(function () {
          Ember.run(function () {
            _this.flashMessages.clearMessages().alert("There was an error");

            reject();
          });
        });
      });
    },
    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        this.changeset.validate().then(function () {
          if (_this2.changeset.get('isValid')) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
              return (0, _fetch.default)('/api/backend/accounts/password', {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                  "reset_password_token": _this2.token,
                  "password": _this2.changeset.get('password'),
                  "password_confirmation": _this2.changeset.get('passwordConfirmation')
                })
              }).then(function (response) {
                if (response.ok) {
                  Ember.run(function () {
                    _this2.flashMessages.clearMessages().success('Password has been reset');

                    _this2.router.transitionTo('sign-in');

                    resolve();
                  });
                } else {
                  response.json().then(function (reason) {
                    _this2.addErrorsToChangeset(reason);

                    Ember.run(function () {
                      _this2.flashMessages.clearMessages().alert("There was an error");

                      reject();
                    });
                  });
                }
              }).catch(function () {
                Ember.run(function () {
                  _this2.flashMessages.clearMessages().alert("There was an error");

                  reject();
                });
              });
            });
          } else {
            _this2.flashMessages.clearMessages().alert('There was an error');

            return;
          }
        });
      }
    },
    addErrorsToChangeset: function addErrorsToChangeset(response) {
      var _this3 = this;

      response.errors.forEach(function (_ref) {
        var source = _ref.source,
            detail = _ref.detail;
        var paths = source.pointer.split('/');
        var attr = paths[paths.length - 1].split('-').join(' ');

        _this3.changeset.pushErrors(attr, "".concat(String(attr).capitalize(), " ").concat(detail));
      });
    }
  });

  _exports.default = _default;
});