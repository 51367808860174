define("lightway-connect/components/roles/role-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissions: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkForExistingPermission();
    },
    checkForExistingPermission: function checkForExistingPermission() {
      var self = this;

      if (this.permissionIsPermitted() == true) {
        this.set('permissionPermitted', true); // Set to false by default

        self.set('permissionExists', false); // If a matching permission exists, change to true

        this.role.get('permissions').forEach(function (permission) {
          if (permission.get('kind') == self.kind) {
            self.replaceEnableButtonWithDisable();
          }
        });
      } else {
        self.replaceButtonWithListItem();
      }
    },
    permissionIsPermitted: function permissionIsPermitted() {
      return this.permissions.accountPermissionsInclude([this.kind]);
    },
    replaceEnableButtonWithDisable: function replaceEnableButtonWithDisable() {
      this.set('permissionExists', true);
    },
    replaceDisableButtonWithEnable: function replaceDisableButtonWithEnable() {
      this.set('permissionExists', false);
    },
    replaceButtonWithListItem: function replaceButtonWithListItem() {
      this.set('permissionPermitted', false);
    },
    actions: {
      enablePermission: function enablePermission() {
        var _this = this;

        var self = this; // create permission record

        var permission = this.store.createRecord('permission', {
          role: this.role,
          kind: this.kind
        });
        permission.save().then(function () {
          self.replaceEnableButtonWithDisable();
          return;
        }).catch(function () {
          _this.flashMessages.clearMessages().alert('There was an error');

          _this.store.unloadRecord(permission);

          return;
        });
      },
      disablePermission: function disablePermission() {
        var self = this;
        self.role.get('permissions').forEach(function (p) {
          if (p.get('kind') == self.kind) {
            // Destroy permission
            p.destroyRecord();
            self.replaceDisableButtonWithEnable();
            return;
          }
        });
      }
    }
  });

  _exports.default = _default;
});