define("lightway-connect/components/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    currentSection: Ember.computed('router.currentRouteName', function () {
      var currentRoute = this.router.get('currentRouteName');

      if (currentRoute) {
        return currentRoute.includes(this.link);
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});