define("lightway-connect/templates/components/content-editor/parts/background-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kjyB7kSS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"backgroundVideoReference\"]]],null,{\"statements\":[[0,\"  \"],[7,\"video\",true],[10,\"loop\",\"\"],[10,\"muted\",\"\"],[10,\"playsInline\",\"\"],[10,\"id\",\"video-background\"],[8],[0,\"\\n    \"],[7,\"source\",true],[11,\"src\",[23,0,[\"videoUrl\"]]],[10,\"type\",\"video/mp4\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"video-overlay\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/background-video.hbs"
    }
  });

  _exports.default = _default;
});