define("lightway-connect/templates/components/content-editor/css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VtHlUQM9",
    "block": "{\"symbols\":[\"part\",\"p\",\"PreviewObject\",\"itemRegion\",\"PreviewObject\"],\"statements\":[[4,\"each\",[[23,0,[\"parts\"]]],null,{\"statements\":[[0,\"  \"],[5,\"content-editor/current-properties\",[],[[\"@model\",\"@prototypeBasedPart\"],[[23,1,[]],[23,0,[\"prototypeBasedPart\"]]]],{\"statements\":[[0,\"\\n  \\n    \"],[5,\"content-editor/part-css\",[],[[\"@part\",\"@previewObject\",\"@viewport\"],[[23,1,[]],[23,5,[]],[23,0,[\"viewport\"]]]]],[0,\"\\n    \\n  \"]],\"parameters\":[5]}],[0,\"\\n\\n  \"],[5,\"content-editor/css\",[],[[\"@parts\",\"@prototypeBasedPart\"],[[23,1,[\"parts\"]],[23,0,[\"prototypeBasedPart\"]]]]],[0,\"\\n  \\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"kind\"]],\"region\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"content-editor/item-region\",[],[[\"@region\",\"@prototypeBasedPart\"],[[23,1,[]],[23,0,[\"prototypeBasedPart\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"content-editor/css\",[],[[\"@parts\",\"@canvasVersion\",\"@prototypeBasedPart\"],[[23,4,[\"parts\"]],[23,0,[\"canvasVersion\"]],[23,0,[\"prototypeBasedPart\"]]]]],[0,\"\\n    \"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"if\",[[23,1,[\"basePrototype\"]]],null,{\"statements\":[[0,\"  \\n\"],[4,\"each\",[[23,1,[\"basePrototype\",\"parts\"]]],null,{\"statements\":[[0,\"      \"],[5,\"content-editor/current-properties\",[],[[\"@model\",\"@prototypeBasedPart\"],[[23,2,[]],[23,1,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"content-editor/part-css\",[],[[\"@prototypeBasedPart\",\"@part\",\"@previewObject\",\"@viewport\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,0,[\"viewport\"]]]]],[0,\"\\n        \"],[5,\"content-editor/css\",[],[[\"@parts\",\"@prototypeBasedPart\"],[[23,2,[\"parts\"]],[23,1,[]]]]],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/css.hbs"
    }
  });

  _exports.default = _default;
});