define("lightway-connect/components/roles/invitation-form", ["exports", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/roles/invitation-form"], function (_exports, _emberChangeset, _emberChangesetValidations, _invitationForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.resetForm();
    },
    resetForm: function resetForm() {
      var invitation = this.store.createRecord('invitation', {
        role: this.role
      });
      this.set('invitation', invitation);
      var changeset = new _emberChangeset.default(this.invitation, (0, _emberChangesetValidations.default)(_invitationForm.default), _invitationForm.default);
      this.set('changeset', changeset);
    },
    actions: {
      sendInvitation: function sendInvitation() {
        var _this = this;

        this.changeset.validate().then(function () {
          if (_this.changeset.get("isValid")) {
            _this.changeset.save().then(function () {
              _this.flashMessages.clearMessages().success('Invitation sent');

              _this.resetForm();
            }).catch(function (reason) {
              _this.flashMessages.clearMessages().alert('There was an error');

              _this.formErrors.addErrors(reason, _this.changeset);
            });
          } else {
            _this.flashMessages.clearMessages().alert('There was an error');
          }
        });
      }
    }
  });

  _exports.default = _default;
});