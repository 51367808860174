define("lightway-connect/validations/sites/site-form", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)(true),
    subdomain: (0, _validators.validateFormat)({
      regex: /^[a-zA-Z0-9-_]{3,30}$/
    })
  };
  _exports.default = _default;
});