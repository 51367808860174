define("lightway-connect/templates/sites/site/media/document-folders/document-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "624h6rj9",
    "block": "{\"symbols\":[\"document\",\"@model\"],\"statements\":[[7,\"h3\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.edit\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n      Rename Folder\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.delete\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n      Delete Folder\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"sites/media/upload-document-form\",[],[[\"@folder\"],[[23,2,[]]]]],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\",\"documents\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder.document\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/document-folders/document-folder.hbs"
    }
  });

  _exports.default = _default;
});