define("lightway-connect/controllers/sites/site/settings/user-kinds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    userKinds: Ember.computed('model', function () {
      var userKinds = Ember.A([]);
      this.model.forEach(function (p) {
        if (!p.parent) {
          userKinds.pushObject(p);
        }
      });
      return userKinds;
    }),
    actions: {
      save: function save(userKind) {
        this.target.send('save', userKind);
      },
      delete: function _delete(userKind) {
        var _this = this;

        userKind.destroyRecord().then(function () {
          _this.flashMessages.clearMessages().success('User Kind deleted');
        });
      }
    }
  });

  _exports.default = _default;
});