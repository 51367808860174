define("lightway-connect/templates/components/resources-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QFglBQx0",
    "block": "{\"symbols\":[\"Modal\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"itemToDelete\"]]],null,{\"statements\":[[0,\"  \"],[5,\"delete-modal\",[],[[\"@toggleDeleteModal\",\"@deleteAction\"],[[28,\"action\",[[23,0,[]],\"closeDeleteModal\"],null],[28,\"action\",[[23,0,[]],\"confirmDeletion\"],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Delete: \"],[1,[23,0,[\"itemToDelete\",\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"message\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Are you sure you want to permanently delete this object and any associated data?\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2,[[28,\"hash\",null,[[\"cell\",\"confirmedCell\",\"reorderControl\",\"statusControl\",\"deleteControl\"],[[28,\"component\",[\"blank-component\"],[[\"tagName\"],[\"span\"]]],[28,\"component\",[\"blank-component\"],[[\"tagName\"],[\"span\"]]],[28,\"component\",[\"blank-component\"],[[\"tagName\",\"class\"],[\"span\",\"cell reorder-control\"]]],[28,\"component\",[\"resources-list-status-control\"],[[\"tagName\",\"class\",\"click\",\"data-test-id\"],[\"span\",[23,0,[\"statusClasses\"]],[28,\"action\",[[23,0,[]],\"toggleStatus\"],null],[28,\"concat\",[\"status-\",[23,0,[\"item\",\"id\"]]],null]]]],[28,\"component\",[\"blank-component\"],[[\"tagName\",\"class\",\"click\",\"data-test-delete-item-for\"],[\"span\",\"cell delete-control\",[28,\"action\",[[23,0,[]],\"prepareItemForDeletion\",[23,0,[\"item\"]]],null],[23,0,[\"item\",\"id\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/resources-list-row.hbs"
    }
  });

  _exports.default = _default;
});