define("lightway-connect/components/sites/parts/sidebars/text-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oos7Zx/3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h4\",true],[8],[0,\"Text Properties\"],[9],[0,\"\\n\\nStyle:\\n\"],[7,\"select\",true],[10,\"name\",\"style-class\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"selectStyleClass\"],[[\"value\"],[\"target.value\"]]]],[8],[0,\"\\n  \"],[1,[28,\"select-option\",null,[[\"label\",\"value\",\"valueToMatch\"],[\"Paragraph\",\"p\",[24,[\"selectedElement\",\"element\",\"styleClass\"]]]]],false],[0,\"\\n  \"],[1,[28,\"select-option\",null,[[\"label\",\"value\",\"valueToMatch\"],[\"Heading\",\"h2\",[24,[\"selectedElement\",\"element\",\"styleClass\"]]]]],false],[0,\"\\n  \"],[1,[28,\"select-option\",null,[[\"label\",\"value\",\"valueToMatch\"],[\"Subheading\",\"h3\",[24,[\"selectedElement\",\"element\",\"styleClass\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"remove button alert\"],[3,\"action\",[[23,0,[]],\"removePart\"]],[8],[0,\"\\n  Remove Text\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/sidebars/text-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});