define("lightway-connect/mixins/save-properties", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeRelationship: function serializeRelationship(snapshot, data, rel) {
      var relKind = rel.kind;
      var relKey = rel.key;

      if (this.get("attrs.".concat(relKey, ".serialize")) === true) {
        data.relationships = data.relationships || {};
        var key = this.keyForRelationship(relKey, relKind, 'serialize');
        data.relationships[key] = data.relationships[key] || {};

        if (relKind === "belongsTo") {
          data.relationships[key].data = this.serializeRecord(snapshot.belongsTo(relKey));
        }

        if (relKind === "hasMany") {
          if (snapshot.hasMany(relKey)) {
            data.relationships[key].data = snapshot.hasMany(relKey).map(this.serializeRecord);
          } else {
            data.relationships[key].data = null;
          }
        }
      }
    },
    serializeRecord: function serializeRecord(obj) {
      if (!obj) {
        return null;
      }

      var serialized = obj.serialize();

      if (obj.id) {
        serialized.data.id = obj.id;
      } else {
        if (!serialized.data.attributes) {
          serialized.data.attributes = {};
        }
      } // do not allow embedded relationships (change this when you are ready to build this functionality)
      // delete serialized.data.relationships;


      return serialized.data;
    },
    // 1, 3
    serializeHasMany: function serializeHasMany() {
      this._super.apply(this, arguments);

      this.serializeRelationship.apply(this, arguments);
    },
    // 2
    serializeBelongsTo: function serializeBelongsTo() {
      this._super.apply(this, arguments);

      this.serializeRelationship.apply(this, arguments);
    },
    // updateRecord(json, store) {//inclusionData, store
    // },
    // 4
    normalizeSaveResponse: function normalizeSaveResponse(store, modelName, obj) {
      this.set('recordsToUnload', Ember.A([]));
      var self = this;
      var modelType = (0, _emberInflector.singularize)(obj.data.type);
      var record = store.peekRecord(modelType, obj.data.id);

      if (record == null) {
        store.peekAll(modelType).forEach(function (rec) {
          if (rec.id == null) {
            record = rec;
          }
        });
      } // Properties


      var recordProperties;

      if (record) {
        recordProperties = record.get('properties');
      }

      if (recordProperties) {
        recordProperties.forEach(function (property) {
          self.filterProperty(store, property);
        });
      }

      return this._super(store, modelName, obj);
    },
    filterProperty: function filterProperty(store, property) {
      var self = this; // Translations

      var propertyTranslations;

      if (property) {
        propertyTranslations = property.get('translations');
      }

      if (propertyTranslations) {
        propertyTranslations.forEach(function (translation) {
          self.filterTranslation(store, translation);
        });
      } // Removed Translations


      var propertyRemovedTranslations;

      if (property) {
        propertyRemovedTranslations = property.get('removedTranslations');
      }

      if (propertyRemovedTranslations) {
        propertyRemovedTranslations.forEach(function (translation) {
          self.recordsToUnload.addObject(translation);
        });
      } // If property is marked as new, remove it


      if (property.get('isNew')) {
        self.recordsToUnload.addObject(property);
      }
    }
  });

  _exports.default = _default;
});