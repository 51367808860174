define("lightway-connect/components/if-array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    itemIsWithinArray: Ember.computed('item', 'array.@each', function () {
      return this.array.includes(this.item);
    })
  });

  _exports.default = _default;
});