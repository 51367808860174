define("lightway-connect/templates/components/changeset-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Chbu/Xby",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"errorIsForProperty\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[1,[23,0,[\"error\",\"validation\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/changeset-error.hbs"
    }
  });

  _exports.default = _default;
});