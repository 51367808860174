define("lightway-connect/components/add-object-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    property: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setUpProperty();
      this.setUpPreference();
    },
    setUpProperty: function setUpProperty() {
      if (!this.model.get("preferences.".concat(this.property))) {
        this.model.set("preferences.".concat(this.property), Ember.A([]));
      }
    },
    setUpPreference: function setUpPreference() {
      this.obj = {};
    }
  });

  _exports.default = _default;
});