define("lightway-connect/components/content-editor/page-link", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    subjectType: Ember.computed('model.pageLink.page.preferences.subject-type.type', function () {
      if (this.model.pageLink && this.model.pageLink.page) {
        return (0, _emberInflector.pluralize)(this.model.pageLink.page.get('preferences.subject-type.type'));
      }

      return null;
    }),
    dynamicContainer: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer;
    }),
    withinDynamicContainer: Ember.computed('dynamicContainer', function () {
      return this.dynamicContainer;
    }),
    dynamicProperties: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.dynamicContainer.get('preferences.dynamic') == "collection" || this.dynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        var type = this.dynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          var kindId = this.dynamicContainer.get('preferences.subject-type.id');
          var kind = this.store.peekRecord('data-kind', kindId);
          var fields = Ember.A([]);
          kind.fields.forEach(function (field) {
            if (field.kind != 'association-field') {
              fields.addObject(field);
            }
          });
          return fields;
        }
      }

      if (this.subjectType) {
        if (this.subjectType.type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name",
            type: "property"
          }]);
        } else {
          // return this.subjectType.fields
          var _fields = Ember.A([]);

          this.subjectType.fields.forEach(function (field) {
            if (field.kind === 'association-field') {
              _fields.addObject(field);
            }
          });
          return _fields;
        }
      } else if (this.subject) {
        // return this.subject.parent.fields
        var _fields2 = Ember.A([]);

        this.subject.parent.fields.forEach(function (field) {
          if (field.kind != 'association-field') {
            _fields2.addObject(field);
          }
        });
        return _fields2;
      } else if (this.model.parent.currentDynamicContainer.get('preferences.dynamic') == "collection" || this.model.parent.currentDynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        var _type = this.model.parent.currentDynamicContainer.get('preferences.subject-type.type');

        if (_type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (_type == 'data-kind') {
          var _kindId = this.model.parent.currentDynamicContainer.get('preferences.subject-type.id');

          var _kind = this.store.peekRecord('data-kind', _kindId);

          var _fields3 = Ember.A([]);

          _kind.fields.forEach(function (field) {
            if (field.kind === 'association-field') {
              _fields3.addObject(field);
            }
          });

          return _fields3;
        }
      } else {
        return null;
      }

      return null;
    }),
    dynamicLinkProperty: Ember.computed('model.preferences.dynamic-link-property.id', function () {
      if (this.model.get('preferences.dynamic-link-property')) {
        var propertyId = this.model.get('preferences.dynamic-link-property.id');

        if (propertyId) {
          return this.store.peekRecord('data-field', propertyId);
        }
      }

      return null;
    }),
    dynamicAssociations: Ember.computed('subjectType', function () {
      var _this = this;

      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.subjectType) {
        var associations = Ember.A([]);
        this.subjectType.allFields.forEach(function (field) {
          if (field.kind === 'association-field') {
            // If reverse field
            if (field.subjectKind != _this.model.parent) {
              associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return associations;
      } else if (this.subject) {
        var _associations = Ember.A([]);

        this.subject.parent.fields.forEach(function (field) {
          if (field.kind == 'association-field') {
            // If reverse field
            if (field.subjectKind != _this.model.parent) {
              _associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              _associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return _associations;
      }

      return null;
    }),
    actions: {
      // For internal page link
      toggleModalForPageLinkPage: function toggleModalForPageLinkPage() {
        this.toggleProperty('selectingPage');
      },
      selectPageForLink: function selectPageForLink(page) {
        var modifications = Ember.A([]);

        if (!this.model.pageLink) {
          var pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          var addHash = this.editorService.prepareAdd(pageLink);
          modifications.addObject(addHash);
        }

        var editHash = this.editorService.prepareEdit(this.model.pageLink, {
          'page': page
        });
        modifications.addObject(editHash);
        this.editorService.addModifications(modifications);
        this.set('selectingPage', false);
      },
      cancelPageSelection: function cancelPageSelection() {
        this.set('selectingPage', false);
      },
      removePageLink: function removePageLink() {
        var _this2 = this;

        this.model.pageLink.destroyRecord().then(function () {
          _this2.flashMessages.clearMessages().success('Link removed');
        }).catch(function () {
          _this2.flashMessages.clearMessages().alert('There was an error');
        });
      },
      // For dynamic page link
      toggleModalForPageLinkSubject: function toggleModalForPageLinkSubject() {
        this.toggleProperty('selectingSubject');
      },
      selectSubjectForLink: function selectSubjectForLink(subject) {
        var editHash = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': subject
        });
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingSubject', false);
      },
      removePageLinkSubject: function removePageLinkSubject() {
        var editHash;

        if (this.model.pageLink) {
          editHash = this.editorService.prepareEdit(this.model.pageLink, {
            'resource': null
          });
        } else {
          editHash = this.editorService.prepareEdit(this.model, {
            'preferences.dynamic-link-property': null
          });
        }

        this.editorService.addModifications(Ember.A([editHash]));
      },
      // For dynamic page link with subject as data item's link-field property
      toggleModalForLinkProperty: function toggleModalForLinkProperty() {
        this.toggleProperty('selectingLinkProperty');
      },
      cancelLinkPropertySelection: function cancelLinkPropertySelection() {
        this.set('selectingLinkProperty', false);
      },
      selectLinkProperty: function selectLinkProperty(value, associationField) {
        var dynamicValue;

        if ((this.dynamicContainer.get('preferences.dynamic') == "collection" || this.dynamicContainer.get('preferences.dynamic') == "grouped-collection") && this.dynamicContainer.get('preferences.subject-type.type') != "pages") {
          dynamicValue = {
            type: "data-field",
            id: value.id
          };

          if (associationField) {
            dynamicValue['association-field-id'] = associationField.id;
          }
        }

        var editHash = this.editorService.prepareEdit(this.model, {
          'preferences.dynamic-link-property': dynamicValue
        });
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingLinkProperty', false); //
      },
      // For document link
      toggleModalForPageLinkDocument: function toggleModalForPageLinkDocument() {
        this.toggleProperty('selectingDocument');
      },
      selectDocumentForLink: function selectDocumentForLink(document) {
        var modifications = Ember.A([]);

        if (!this.model.pageLink) {
          var pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          var editHash1 = this.editorService.prepareEdit(this.model, {
            'pageLink': pageLink
          });
          modifications.addObject(editHash1);
        }

        var editHash2 = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': document
        });
        modifications.addObject(editHash2);
        this.editorService.addModifications(modifications);
        this.set('selectingDocument', false);
      },
      cancelDocumentSelection: function cancelDocumentSelection() {
        this.set('selectingDocument', false);
      },
      toggleModalForPageLinkPrototype: function toggleModalForPageLinkPrototype() {
        this.toggleProperty('selectingPrototype');
      },
      selectPrototypeForLink: function selectPrototypeForLink(prototype) {
        var modifications = Ember.A([]);

        if (!this.model.pageLink) {
          var pageLink = this.store.createRecord('page-link', {
            part: this.model
          });
          var editHash1 = this.editorService.prepareEdit(this.model, {
            'pageLink': pageLink
          });
          modifications.addObject(editHash1);
        }

        var editHash2 = this.editorService.prepareEdit(this.model.pageLink, {
          'resource': prototype
        });
        modifications.addObject(editHash2);
        this.editorService.addModifications(modifications);
        this.set('selectingPrototype', false);
      },
      cancelPrototypeSelection: function cancelPrototypeSelection() {
        this.set('selectingPrototype', false);
      }
    }
  });

  _exports.default = _default;
});