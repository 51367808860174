define("lightway-connect/templates/components/content-editor/parts/form-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SQ1KGrNb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"identifier\"]]]]],[8],[0,\"\\n\\n  \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,0,[\"part\"]],[23,0,[\"part\",\"partsSortedBound\"]],[23,0,[\"orientationOverride\"]],\"group\",[23,0,[\"prototypeBasedPart\"]],[23,0,[\"loopDynamicContainers\"]],[23,0,[\"loopItem\"]],true,[23,0,[\"selectPartAction\"]],[23,0,[\"dropPartAction\"]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/form-part.hbs"
    }
  });

  _exports.default = _default;
});