define("lightway-connect/templates/sites/site/settings/contributors/roles/role/contributors/contributor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTDrtW8l",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n  Contributor: \"],[1,[23,1,[\"contributor\",\"firstName\"]],false],[0,\" \"],[1,[23,1,[\"contributor\",\"lastName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[1,[23,1,[\"role\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"link-to\",[[12,\"class\",\"button alert\"]],[[\"@route\",\"@model\"],[\"sites.site.settings.contributors.roles.role.contributors.contributor.remove\",[23,1,[\"contributor\",\"id\"]]]],{\"statements\":[[0,\"\\n  Remove \"],[1,[23,1,[\"contributor\",\"firstName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/contributors/roles/role/contributors/contributor.hbs"
    }
  });

  _exports.default = _default;
});