define("lightway-connect/components/accounts/sign-up-form", ["exports", "fetch", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/accounts/sign-up-form"], function (_exports, _fetch, _emberChangeset, _emberChangesetValidations, _signUpForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('mod', {});
      var changeset = new _emberChangeset.default(this.mod, (0, _emberChangesetValidations.default)(_signUpForm.default), _signUpForm.default);
      this.set('changeset', changeset);
    },
    hideForm: function hideForm() {
      this.set('formIsHidden', true);
    },
    actions: {
      register: function register() {
        var _this = this;

        this.changeset.validate().then(function () {
          if (_this.changeset.get('isValid')) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
              return (0, _fetch.default)('/api/backend/accounts', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                  "data": {
                    "type": "registrations",
                    "attributes": {
                      "first-name": _this.changeset.get('firstName'),
                      "last-name": _this.changeset.get('lastName'),
                      "email": _this.changeset.get('email'),
                      "password": _this.changeset.get('password'),
                      "password-confirmation": _this.changeset.get('passwordConfirmation'),
                      "invitation-token": _this.invitationToken
                    }
                  }
                })
              }).then(function (response) {
                response.json().then(function (responseJson) {
                  Ember.run(function () {
                    _this.flashMessages.clearMessages().success('Account registered');

                    if (responseJson && responseJson.confirmed) {
                      // Email is already confirmed due to signup via invitation token
                      // so redirect to sign in form
                      _this.router.transitionTo('sign-in');
                    } else {
                      // Email is not yet confirmed
                      _this.hideForm();
                    }

                    return resolve();
                  });
                }).catch(function () {
                  Ember.run(function () {
                    // Email is not yet confirmed
                    _this.hideForm();

                    _this.flashMessages.clearMessages().success('Account registered');

                    return resolve();
                  });
                });
              }).catch(function () {
                Ember.run(function () {
                  _this.flashMessages.clearMessages().alert('There was an error');

                  return reject();
                });
              });
            });
          } else {
            _this.flashMessages.clearMessages().alert('There was an error');
          }
        });
      }
    }
  });

  _exports.default = _default;
});