define("lightway-connect/templates/components/accounts/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5/feX8+s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12 medium-8 large-6 card invitation-card\"],[8],[0,\"\\n\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    Invitation\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[0,\"\\n    You have been invited to be a contributor for the site \"],[7,\"b\",true],[8],[7,\"i\",true],[8],[1,[23,0,[\"invitation\",\"siteName\"]],false],[9],[9],[0,\".\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"button-group\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"button success\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"acceptInvitation\"]],[8],[0,\"\\n      Accept\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"button\",false],[12,\"class\",\"button alert\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"declineInvitation\"]],[8],[0,\"\\n      Decline\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/accounts/invitation.hbs"
    }
  });

  _exports.default = _default;
});