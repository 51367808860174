define("lightway-connect/components/sites/roles/delete-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkForContributors();
    },
    checkForContributors: function checkForContributors() {
      var areThereNoContribs = this.role.get('contributors.length') == 0;
      this.set('noContributors', areThereNoContribs);
    }
  });

  _exports.default = _default;
});