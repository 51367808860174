define("lightway-connect/templates/components/content-editor/parts/cta-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GpWRC14",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content p\",[23,0,[\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"identifier\"]]]]],[8],[0,\"\\n\\n  \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@group\",\"@draggingEnabled\",\"@prototypeBasedPart\",\"@selectPartAction\",\"@dropPartAction\"],[[23,0,[\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\"]],[23,0,[\"part\",\"basePrototype\",\"partsSortedBound\",\"firstObject\",\"partsSortedBound\"]],[23,0,[\"listGroup\"]],false,[23,0,[\"part\"]],[23,0,[\"selectPartAction\"]],[23,0,[\"dropPartAction\"]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/parts/cta-part.hbs"
    }
  });

  _exports.default = _default;
});