define("lightway-connect/templates/components/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pJvoz/hf",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"cell small-12 medium-shrink\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"grid-x full-height\"],[8],[0,\"\\n    \"],[5,\"responsive-menu-item\",[],[[\"@menu\"],[[23,0,[\"menu\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"cell shrink\"]],[[\"@route\",\"@model\"],[[23,0,[\"link\"]],[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[29,[\"grid-x align-middle nav-label \",[28,\"if\",[[23,0,[\"currentSection\"]],\"current-section\"],null]]]],[8],[0,\"\\n          \"],[1,[23,0,[\"linkName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/nav-item.hbs"
    }
  });

  _exports.default = _default;
});