define("lightway-connect/components/content-editor/parts/section-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    firstPart: Ember.computed('part.basePrototype.parts.[]', function () {
      return this.part.basePrototype.partsSortedBound.get('firstObject');
    })
  });

  _exports.default = _default;
});