define("lightway-connect/templates/reset-password/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xtfiN8gN",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-8 large-6 card\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Reset Password\"],[9],[0,\"\\n\\n      \"],[5,\"accounts/reset-password-form\",[],[[\"@token\"],[[23,1,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/reset-password/token.hbs"
    }
  });

  _exports.default = _default;
});