define("lightway-connect/templates/components/responsive-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "owp2MYTR",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"isActive\",\"toggleMenu\",\"toggleButton\",\"item\",\"siteMenu\"],[[23,0,[\"isActive\"]],[28,\"action\",[[23,0,[]],\"toggleMenu\"],null],[28,\"component\",[\"responsive-menu-toggle\"],null],[28,\"component\",[\"responsive-menu-item\"],[[\"menu\",\"model\"],[[23,0,[]],[23,1,[]]]]],[28,\"component\",[\"site-menu\"],[[\"menu\",\"model\"],[[23,0,[]],[23,0,[\"currentSite\",\"site\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/responsive-menu.hbs"
    }
  });

  _exports.default = _default;
});