define("lightway-connect/components/button-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model) {
    return Ember.computed(key, "model.".concat(key), 'finalProperty', function () {
      return model.get(key);
    });
  }

  var _default = Ember.Component.extend({
    classNameBindings: ['matches:enabled', 'valueClass'],
    editorService: Ember.inject.service(),
    valueClass: Ember.computed('valueToMatch', function () {
      return this.valueToMatch;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'value', valueFromProperty(this.propertyPath, this.model));
    },
    matches: Ember.computed('value', function () {
      return this.value == this.valueToMatch;
    }),
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    click: function click() {
      var changes = {};

      if (this.matches) {
        changes[this.propertyPath] = null;
      } else {
        changes[this.propertyPath] = this.valueToMatch;
      }

      var edit = this.editorService.prepareEdit(this.model, changes);
      this.editorService.addModifications(Ember.A([edit]));
    }
  });

  _exports.default = _default;
});