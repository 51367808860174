define("lightway-connect/templates/components/layouts/main-reduced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k7unji0C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-12 medium-10 medium-cell-block-y\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-y content-container\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"grid-x\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n              \"],[7,\"main\",true],[10,\"class\",\"grid-container fluid\"],[8],[0,\"\\n                \"],[14,1],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"cell stick\"],[8],[0,\"\\n      \"],[5,\"footer\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/layouts/main-reduced.hbs"
    }
  });

  _exports.default = _default;
});