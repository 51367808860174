define("lightway-connect/templates/components/ui-form/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvs6oI+w",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-y grid-frame align-center\"],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"option\"],[[28,\"component\",[\"ui-form/select-option\"],[[\"model\",\"association\",\"selectBoxIsOpen\",\"currentValue\",\"currentItem\",\"selectAction\"],[[23,1,[]],[23,0,[\"association\"]],[23,0,[\"isOpen\"]],[23,0,[\"currentValue\"]],[23,0,[\"currentItem\"]],[28,\"action\",[[23,0,[]],\"selectAction\"],null]]]]]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[23,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-y grid-frame align-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"selector-arrow\"],[8],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/select-box.hbs"
    }
  });

  _exports.default = _default;
});