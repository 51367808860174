define("lightway-connect/controllers/sites/site/design/layouts/new", ["exports", "lightway-connect/validations/layout"], function (_exports, _layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_layout.default, {
    actions: {
      saveLayout: function saveLayout(model) {
        this.target.send('saveLayout', model);
      }
    }
  });

  _exports.default = _default;
});