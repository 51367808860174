define("lightway-connect/templates/components/data/item-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nP1d7tU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[\"property\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/data/item-property.hbs"
    }
  });

  _exports.default = _default;
});