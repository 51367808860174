define("lightway-connect/controllers/sites/site/settings/domains/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    flashMessages: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    actions: {
      savePrimaryDomainFor: function savePrimaryDomainFor(site) {
        this.target.send('savePrimaryDomainFor', site);
      },
      setAsPrimary: function setAsPrimary(domain) {
        var _this = this;

        domain.site.set('primaryDomain', domain);
        domain.save().then(function () {
          _this.flashMessages.clearMessages().success('Domain set as primary');
        });
      },
      deleteDomain: function deleteDomain(domain) {
        var _this2 = this;

        domain.destroyRecord().then(function () {
          _this2.flashMessages.clearMessages().success('Domain deleted');
        });
      }
    }
  });

  _exports.default = _default;
});