define("lightway-connect/templates/policies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PU9I/F5y",
    "block": "{\"symbols\":[],\"statements\":[[5,\"header\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height\"],[8],[0,\"\\n    \"],[5,\"layouts/section-bar\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"policies\"]],{\"statements\":[[0,\"\\n          Policies\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"ul\",true],[10,\"class\",\"grid-x section-menu settings\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\"],[\"policies.privacy\"]],{\"statements\":[[0,\"\\n            Privacy Policy\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"layouts/main-reduced\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/policies.hbs"
    }
  });

  _exports.default = _default;
});