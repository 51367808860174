define("lightway-connect/components/content-editor/properties/cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-part-settings'],
    editorService: Ember.inject.service(),
    prototypes: Ember.computed('editorService.{inPageMode,inLayoutMode,inItemMode,inEssentialContentItemMode,inKindMode}', function () {
      var prototypes = Ember.A([]);
      var prototypesParent;

      if (this.editorService.inPageMode) {
        prototypesParent = this.editorService.model.layout;
      }

      if (this.editorService.inItemMode || this.editorService.inEssentialContentItemMode) {
        prototypesParent = this.editorService.model.parent;
      }

      if (this.editorService.inKindMode) {
        prototypesParent = this.editorService.model;
      }

      if (this.editorService.inLayoutMode) {
        prototypesParent = this.editorService.model;
      }

      prototypesParent.prototypes.forEach(function (prototype) {
        if (prototype.kind == "cta") {
          prototypes.addObject(prototype);
        }
      });
      return prototypes;
    }),
    overridables: Ember.computed('model.basePrototype', function () {
      var _this = this;

      var overrides = Ember.A([]);
      this.model.basePrototype.combinedParts.forEach(function (pt) {
        _this.addOverrideFor(pt, overrides);
      });
      return overrides;
    }),
    addOverrideFor: function addOverrideFor(part, overrides) {
      var _this2 = this;

      if (part.preferences.overridable && part.preferences.overridable != true) {
        part.preferences.overridable.forEach(function (overridable) {
          var ovrr = {
            'part-id': part.id,
            'property-name': overridable
          };
          overrides.addObject(ovrr);
        });
      }

      part.combinedParts.forEach(function (pt) {
        _this2.addOverrideFor(pt, overrides);
      });
    }
  });

  _exports.default = _default;
});