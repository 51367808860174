define("lightway-connect/mixins/data-item-parentable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    items: (0, _model.hasMany)('data-item', {
      inverse: 'parent'
    })
  });

  _exports.default = _default;
});