define("lightway-connect/components/content-editor/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    tagName: '',
    propertyPath: Ember.computed('editorService.viewport.size', function () {
      if (this.overrideHash) {
        return this.shortProperty;
      }

      if (this.propertyPathOverride) {
        return this.propertyPathOverride;
      }

      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    }),
    actions: {
      selectAction: function selectAction(value) {
        var _this = this;

        var changes = {};

        if (this.overrideHash) {
          var newOverrides = Ember.A([]);
          this.model.get("preferences.".concat(this.editorService.viewport.size, ".overrides")).forEach(function (override) {
            if (Ember.get(override, 'part-id') === "".concat(_this.overrideHash['part-id'])) {
              var newOverride = {};
              Object.keys(override).forEach(function (key) {
                newOverride[key] = override[key];
              });
              newOverride[_this.propertyPath] = value;
              newOverrides.addObject(newOverride);
            } else {
              newOverrides.addObject(override);
            }
          });
          changes["preferences.".concat(this.editorService.viewport.size, ".overrides")] = newOverrides;
        } else {
          changes[this.propertyPath] = value;
        }

        var editHash = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([editHash]));

        if (this.afterSelect) {
          this.afterSelect(value);
        }
      }
    }
  });

  _exports.default = _default;
});