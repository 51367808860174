define("lightway-connect/components/sites/data/fields-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    permitted: true,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkForPermission();
    },
    checkForPermission: function checkForPermission() {
      var approvedPermissions = ['data_kinds'];
      var permitted = this.permissions.accountPermissionsInclude(approvedPermissions) == true;
      this.set('permitted', permitted);
    }
  });

  _exports.default = _default;
});