define("lightway-connect/templates/components/roles/role-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ng3E/Lsy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"permissionExists\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"permissionPermitted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell small-12 medium-6 large-4 permission-control permitted\"],[3,\"action\",[[23,0,[]],\"disablePermission\",[23,0,[\"role\"]],[23,0,[\"kind\"]]]],[8],[0,\"\\n\\n      \"],[7,\"span\",true],[10,\"class\",\"grid-x align-left align-middle\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"cell permission-icon permission-icon-enabled\"],[8],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"span\",true],[10,\"class\",\"cell auto permission-label\"],[8],[0,\"\\n          \"],[1,[23,0,[\"kindLabel\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"permissionPermitted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell small-12 medium-6 large-4 permission-control permitted\"],[3,\"action\",[[23,0,[]],\"enablePermission\",[23,0,[\"role\"]],[23,0,[\"kind\"]]]],[8],[0,\"\\n\\n      \"],[7,\"span\",true],[10,\"class\",\"grid-x align-left align-middle\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"cell permission-icon permission-icon-disabled\"],[8],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"span\",true],[10,\"class\",\"cell auto permission-label\"],[8],[0,\"\\n          \"],[1,[23,0,[\"kindLabel\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/roles/role-permission.hbs"
    }
  });

  _exports.default = _default;
});