define("lightway-connect/templates/sites/site/media/document-folders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSbp89Is",
    "block": "{\"symbols\":[\"folder\"],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n  Document Folders\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"document-folders\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"folders\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"documentFolders\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"sites.site.media.document-folders.document-folder\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"new-folder\"]],[[\"@route\"],[\"sites.site.media.document-folders.new\"]],{\"statements\":[[0,\"\\n        New Folder\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/media/document-folders.hbs"
    }
  });

  _exports.default = _default;
});