define("lightway-connect/templates/components/content-editor/draggable-parts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7C3XeDDH",
    "block": "{\"symbols\":[\"child\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"dpl-og grid-y \",[23,0,[\"directionXClassReverse\"]],\" \",[23,0,[\"directionYClassReverse\"]],\" full-width full-height\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dpl-oc cell small-12\"],[8],[0,\"\\n\\n    \"],[5,\"drag-sort-container\",[],[[\"@orientation\",\"@viewport\",\"@items\",\"@group\",\"@handle\",\"@draggingEnabled\",\"@directionClasses\",\"@class\",\"@directionXClassOverride\",\"@directionYClass\",\"@selectPartAction\",\"@dragEndAction\"],[[23,0,[\"orientation\"]],[23,0,[\"editorService\",\"viewport\"]],[23,0,[\"items\"]],[23,0,[\"group\"]],\".move-tool\",[23,0,[\"draggingEnabled\"]],[23,0,[\"directionClasses\"]],[23,0,[\"listClasses\"]],[23,0,[\"directionXClass\"]],[23,0,[\"directionYClass\"]],[23,0,[\"selectPartAction\"]],[23,0,[\"dropPartAction\"]]]],{\"statements\":[[0,\"\\n\\n      \"],[1,[28,\"component\",[[28,\"concat\",[\"content-editor/parts/\",[23,1,[\"kind\"]],\"-part\"],null]],[[\"part\",\"parentXDirectionClass\",\"parentYDirectionClass\",\"orientation\",\"viewport\",\"group\",\"prototypeBasedPart\",\"loopDynamicContainers\",\"loopItem\",\"selectPartAction\",\"dropPartAction\"],[[23,1,[]],[23,0,[\"directionXClassReverse\"]],[23,0,[\"directionYClassReverse\"]],[23,0,[\"orientation\"]],[23,0,[\"editorService\",\"viewport\"]],[28,\"eq\",[[23,1,[\"kind\"]],\"container\",\"nested-group\"],null],[23,0,[\"prototypeBasedPart\"]],[23,0,[\"loopDynamicContainers\"]],[23,0,[\"loopItem\"]],[23,0,[\"selectPartAction\"]],[23,0,[\"dropPartAction\"]]]]],false],[0,\"\\n\\n    \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/draggable-parts-list.hbs"
    }
  });

  _exports.default = _default;
});