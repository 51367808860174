define("lightway-connect/components/ui-select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model) {
    return Ember.computed(key, "model.".concat(key), 'finalProperty', function () {
      return model.get(key);
    });
  }

  var _default = Ember.Component.extend({
    finalProperty: Ember.computed('property', function () {
      var fP = this.property;

      if (this.pathExtension) {
        if (!this.model.get(this.property)) {
          this.model.set(this.property, {});
        }

        fP += this.pathExtension;
      }

      return fP;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // Set value to computed property with dynamic dependent key


      Ember.defineProperty(this, 'value', valueFromProperty(this.finalProperty, this.model));
    },
    inputId: Ember.computed({
      get: function get() {
        return Ember.guidFor(this) + '-inpudid';
      }
    }),
    noValue: Ember.computed('value', function () {
      return !this.value;
    }),
    actions: {
      openBox: function openBox() {
        this.set('isOpen', true);
      },
      selectOption: function selectOption(option) {
        if (option == "") {
          this.model.set(this.finalProperty, null);
        } else {
          this.model.set(this.finalProperty, option);
        }

        this.set('isOpen', false);

        if (this.saveWhenSelected) {
          this.saveAction(this.model);
        }
      }
    }
  });

  _exports.default = _default;
});