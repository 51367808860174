define("lightway-connect/templates/components/content-editor/properties/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3LnrxvtI",
    "block": "{\"symbols\":[\"Panel\",\"overridable\",\"Module\",\"Box\",\"prototype\",\"@model\",\"&default\"],\"statements\":[[5,\"content-editor/setting-panel\",[[12,\"class\",\"settings-panel properties\"]],[[\"@saveAction\",\"@model\",\"@isOpen\"],[[23,0,[\"saveAction\"]],[23,6,[]],true]],{\"statements\":[[0,\"\\n\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Section Properties\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"module\"]],[],[[\"@identifier\",\"@property\",\"@isOpen\"],[\"basePrototype\",\"basePrototype\",true]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Type\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[6,[23,3,[\"selectBox\"]],[],[[\"@property\",\"@propertyPathOverride\",\"@afterSelect\"],[\"basePrototype\",\"basePrototype\",[28,\"action\",[[23,0,[]],\"generateItemRegions\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"prototypes\"]]],null,{\"statements\":[[0,\"          \"],[6,[23,4,[\"option\"]],[],[[\"@value\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,5,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[5]},null],[0,\"      \"]],\"parameters\":[4]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"overridables\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[28,\"concat\",[\"content-editor/panels/\",[23,2,[\"property-name\"]]],null]],[[\"model\",\"overridable\",\"panel\",\"saveAction\"],[[23,6,[]],[23,2,[]],[23,1,[]],[23,0,[\"saveAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[14,7],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/properties/section.hbs"
    }
  });

  _exports.default = _default;
});