define("lightway-connect/components/ui-form/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model) {
    if (!key) {
      return null;
    } // Add extra 'property' at the end so that triggers a rerender when the property string itself changes


    return Ember.computed(key, "model.".concat(key), 'property', function () {
      return Ember.get(model, key);
    });
  }

  var _default = Ember.Component.extend({
    classNames: ['grid-x', 'select-box'],
    classNameBindings: ['selectBoxOpen'],
    attributeBindings: ['dataTestId:data-test-select-box-for'],
    dataTestId: Ember.computed('property', 'association', function () {
      if (this.property) {
        return this.property;
      }

      if (this.association) {
        return this.association;
      }

      return null;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'currentItem', valueFromProperty(this.association, this.model));
      Ember.defineProperty(this, 'currentValue', valueFromProperty(this.property, this.model));
    },
    currentValue: Ember.computed('association', 'property', function () {
      if (this.association) {
        return null;
      }

      return Ember.get(this.model, this.property);
    }),
    selectBoxOpen: Ember.computed('isOpen', function () {
      return this.isOpen;
    }),
    click: function click() {
      this.toggleProperty('isOpen');
    },
    actions: {
      selectAction: function selectAction(option) {
        if (this.isOpen) {
          if (this.selectAction) {
            this.selectAction(option, this.property, this.association);
          } else {
            if (this.association) {
              Ember.set(this.model, this.association, option);
            } else {
              Ember.set(this.model, this.property, option);
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});