define("lightway-connect/components/accounts/change-password-form", ["exports", "fetch", "ember-changeset", "ember-changeset-validations", "lightway-connect/validations/accounts/change-password-form"], function (_exports, _fetch, _emberChangeset, _emberChangesetValidations, _changePasswordForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_changePasswordForm.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formIsHidden: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('mod', {});
      var changeset = new _emberChangeset.default(this.mod, (0, _emberChangesetValidations.default)(_changePasswordForm.default), _changePasswordForm.default);
      this.set('changeset', changeset);
    },
    hideForm: function hideForm() {
      this.set('formIsHidden', true);
    },
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.changeset.validate().then(function () {
          if (_this.changeset.get('isValid')) {
            var _this$get = _this.get('session.data.authenticated'),
                token = _this$get.token;

            if (_this.get('session.isAuthenticated') && token) {
              var authorizationToken = "Bearer ".concat(token);
              return _this.requestPasswordChange(authorizationToken);
            } else {
              _this.flashMessages.clearMessages().alert('There was an error');

              return;
            }
          } else {
            _this.flashMessages.clearMessages().alert('There was an error');

            return;
          }
        });
      }
    },
    requestPasswordChange: function requestPasswordChange(authorizationToken) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)('/api/backend/accounts', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: authorizationToken
          },
          body: JSON.stringify({
            data: {
              "type": "accounts",
              "attributes": {
                "current-password": _this2.changeset.get('currentPassword'),
                "new-password": _this2.changeset.get('newPassword'),
                "new-password-confirmation": _this2.changeset.get('newPasswordConfirmation')
              }
            }
          })
        }).then(function (response) {
          if (response.ok) {
            _this2.flashMessages.clearMessages().success('Password has been changed');

            _this2.hideForm();

            return resolve();
          } else {
            response.json().then(function (responseJson) {
              Ember.run(function () {
                _this2.flashMessages.clearMessages().alert('There was an error');

                _this2.addErrorsToChangeset(responseJson);

                return reject();
              });
            });
          }
        }).catch(function () {
          Ember.run(function () {
            _this2.flashMessages.clearMessages().alert('There was an error');

            return reject();
          });
        });
      });
    },
    addErrorsToChangeset: function addErrorsToChangeset(responseJSON) {
      var _this3 = this;

      responseJSON.errors.forEach(function (_ref) {
        var source = _ref.source,
            detail = _ref.detail;
        var paths = source.pointer.split('/');
        var attr = paths[paths.length - 1].split('-').join(' ');

        _this3.changeset.pushErrors(attr, "".concat(String(attr).capitalize(), " ").concat(detail));
      });
    }
  });

  _exports.default = _default;
});