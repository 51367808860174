define("lightway-connect/templates/components/resources-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l6i5uBMP",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"itemsSortedBound\"]]],null,{\"statements\":[[0,\"  \"],[5,\"drag-sort-item\",[[12,\"class\",[23,0,[\"childClass\"]]]],[[\"@additionalArgs\",\"@item\",\"@index\",\"@items\",\"@handle\",\"@tagName\",\"@isHorizontal\",\"@isRtl\",\"@draggingEnabled\",\"@sourceOnly\",\"@dragEndAction\",\"@dragStartAction\",\"@determineForeignPositionAction\"],[[23,0,[\"additionalArgs\"]],[23,1,[]],[23,2,[]],[23,0,[\"itemsSortedBound\"]],[23,0,[\"handle\"]],[23,0,[\"childTagName\"]],[23,0,[\"isHorizontal\"]],[23,0,[\"isRtl\"]],[23,0,[\"draggingEnabled\"]],[23,0,[\"sourceOnly\"]],[28,\"action\",[[23,0,[]],\"dragEnd\"],null],[23,0,[\"dragStartAction\"]],[23,0,[\"determineForeignPositionAction\"]]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"item\",\"index\",\"nestedItems\",\"row\"],[[23,1,[]],[23,2,[]],[23,1,[\"itemsSortedBound\"]],[28,\"component\",[\"resources-list-row\"],[[\"tagName\",\"class\",\"item\",\"deleteAction\"],[\"span\",\"grid-x item-row\",[23,1,[]],[23,0,[\"deleteAction\"]]]]]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/resources-list.hbs"
    }
  });

  _exports.default = _default;
});