define("lightway-connect/serializers/data-category", ["exports", "lightway-connect/serializers/application", "lightway-connect/mixins/save-inclusions"], function (_exports, _application, _saveInclusions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_saveInclusions.default, {
    attrs: {
      translations: {
        key: 'translations',
        serialize: true
      },
      removedTranslations: {
        key: 'removed-translations',
        serialize: true
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      if (json.relationships == null) {
        json.relationships = {};
      }

      if (key === 'translations') {
        var translationsJson = json.relationships.translations = {};
        translationsJson.data = [];

        if (snapshot.hasMany('translations')) {
          snapshot.hasMany('translations').forEach(function (object) {
            var translation = {
              type: "translations",
              id: object.id,
              attributes: {
                locale: object._attributes.locale,
                "string-value": object._attributes.stringValue,
                "target-string": object._attributes.targetString
              }
            };
            translationsJson.data.pushObject(translation);
          });
        }

        return json;
      } else if (key === 'removedTranslations') {
        var _translationsJson = json.relationships["removed-translations"] = {};

        _translationsJson.data = [];

        if (snapshot.hasMany('removedTranslations')) {
          snapshot.hasMany('removedTranslations').forEach(function (object) {
            var translation = {
              type: "translations",
              id: object.id
            };

            _translationsJson.data.pushObject(translation);
          });
        }

        return json;
      } else {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});