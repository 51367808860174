define("lightway-connect/components/resource-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    editorService: Ember.inject.service(),
    resources: null,
    type: null,
    mode: null,
    parentId: null,
    parentType: null,
    selectedResources: Ember.A([]),
    selectedResource: null,
    selectedKind: null,
    selectedParent: null,
    selectedCategory: null,
    selectedAssociate: null,
    includePages: false,
    includeUserInvitations: false,
    includeNotifications: false,
    includeResetPasswordConfirmations: false,
    multipleSelectionsPermitted: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.type) {
        if (!this.resources) {
          this.loadResources();
        }
      } else {
        this.loadKinds();
      }
    },
    loadResources: function loadResources() {
      var _this = this;

      var site = this.get('currentSite.site');

      if (this.mode != "peek") {
        this.store.query(this.type, {
          filter: {
            site: site.id,
            'parent_type': this.parentType,
            parent: this.parentId,
            id: !null
          }
        }).then(function (results) {
          _this.set('resources', results);
        });
      }
    },
    loadKinds: function loadKinds() {
      var _this2 = this;

      var site = this.get('currentSite.site');
      this.store.query('data-kind', {
        filter: {
          site: site.id,
          id: !null
        }
      }).then(function (results) {
        _this2.set('kinds', results);
      });
    },
    loadCategories: function loadCategories() {
      var _this3 = this;

      var site = this.get('currentSite.site');
      this.store.query('data-category', {
        filter: {
          site: site.id,
          'parent_type': 'data-kinds',
          parent: this.selectedKind.id,
          id: !null
        }
      }).then(function (results) {
        if (results.length == 0) {
          _this3.set('noCategories', true);

          _this3.set('selectedParent', _this3.selectedKind);

          _this3.loadItems();
        } else {
          _this3.set('categories', results);
        }
      });
    },
    loadItems: function loadItems() {
      var _this4 = this;

      var site = this.get('currentSite.site');
      var parentType;

      if (this.selectedKind && !this.selectedCategory) {
        parentType = "data-kinds";
      } else {
        parentType = "data-categories";
      }

      this.store.query('data-item', {
        filter: {
          site: site.id,
          'parent_type': parentType,
          parent: this.selectedParent.id,
          id: !null
        }
      }).then(function (results) {
        _this4.set('resources', results);
      });
    },
    actions: {
      selectResource: function selectResource(resource) {
        // if (this.multipleSelectionsPermitted) {
        //   if (resource.kind == 'association-field') {
        //     this.set('selectedAssociate', resource)
        //   } else {
        //     this.selectedResources.pushObject(resource)
        //   }
        // } else {
        if (resource.kind == 'association-field') {
          this.set('selectedAssociate', resource);
        } else {
          this.set('selectedResource', resource);
        } // Reload resource from API to obtain all includes
        // this.reloadIfSafe(resource)
        // }

      },
      selectKind: function selectKind(kind) {
        this.set('selectedKind', kind); // Reload kind from API to obtain all includes

        this.reloadIfSafe(kind);
        this.loadCategories();
      },
      selectCategory: function selectCategory(category) {
        this.set('selectedCategory', category);
        this.set('selectedParent', category); // Reload category from API to obtain all includes

        this.reloadIfSafe(category);
        this.loadItems();
      }
    },
    reloadIfSafe: function reloadIfSafe(item) {
      if (item.isA('page')) {
        if (item.versions.include(this.editorService.canvasVersion)) {
          return;
        }
      } else if (item === this.editorService.canvasVersion) {
        return;
      }

      item.reload();
    }
  });

  _exports.default = _default;
});