define("lightway-connect/templates/components/accounts/reset-password-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l/JBDmW6",
    "block": "{\"symbols\":[\"Form\",\"Field\",\"Field\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"approved\"]]],null,{\"statements\":[[0,\"\\n  \"],[5,\"form-errors\",[],[[\"@changeset\"],[[23,0,[\"changeset\"]]]]],[0,\"\\n\\n  \"],[5,\"ui-form\",[],[[\"@onSubmit\",\"@model\"],[[28,\"action\",[[23,0,[]],\"resetPassword\"],null],[23,0,[\"changeset\"]]]],{\"statements\":[[0,\"\\n\\n    \"],[6,[23,1,[\"field\"]],[],[[\"@property\"],[\"password\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n        New Password\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"passwordInput\"]],[],[[\"@autocomplete\"],[\"new-password\"]]],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\\n    \"],[6,[23,1,[\"field\"]],[],[[\"@property\"],[\"password-confirmation\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"label\"]],[],[[],[]],{\"statements\":[[0,\"\\n        Confirm New Password\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,2,[\"passwordInput\"]],[],[[\"@autocomplete\"],[\"new-password\"]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\\n    \"],[6,[23,1,[\"submit\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Submit\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[14,4],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/accounts/reset-password-form.hbs"
    }
  });

  _exports.default = _default;
});