define("lightway-connect/templates/sites/site/settings/data-kinds/data-kind/default-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W8aA4+7l",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[4,\"if\",[[23,1,[\"dataKind\",\"preferences\",\"content-enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"cell auto cell-block-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x small-12\"],[8],[0,\"\\n      \"],[5,\"content-editor\",[],[[\"@model\",\"@tools\",\"@viewportsEnabled\",\"@saveAction\"],[[23,1,[\"dataKind\"]],[23,0,[\"editorTools\"]],false,[28,\"action\",[[23,0,[]],\"saveModel\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/data-kinds/data-kind/default-content.hbs"
    }
  });

  _exports.default = _default;
});