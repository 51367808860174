define("lightway-connect/components/sites/parts/container-part/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQAfgyQs",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"container-part \",[28,\"if\",[[24,[\"elementSelected\"]],\"selected-part\"],null],\" part\"]]],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"edit-container\"],[3,\"action\",[[23,0,[]],\"editContainer\",[24,[\"part\"]]]],[8],[0,\"\\n    Container\\n  \"],[9],[0,\"\\n\\n\"],[4,\"drag-sort-list\",null,[[\"items\",\"group\",\"dragEndAction\"],[[24,[\"part\",\"partsSortedBound\"]],\"nested group\",[28,\"action\",[[23,0,[]],\"dragEnd\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"sites/parts/nested-part\",null,[[\"part\",\"dragEndAction\"],[[23,1,[]],[24,[\"dragEndAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/container-part/template.hbs"
    }
  });

  _exports.default = _default;
});