define("lightway-connect/components/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.label) {
        this.set('label', "Submit");
      }
    }
  });

  _exports.default = _default;
});