define("lightway-connect/mixins/hover-item-rel", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hoverItems: (0, _model.hasMany)('hoverItem', {
      inverse: 'item',
      async: false
    }),
    ownedHoverItems: (0, _model.hasMany)('hoverItem', {
      inverse: 'owner',
      async: false
    })
  });

  _exports.default = _default;
});