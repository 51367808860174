define("lightway-connect/components/content-editor/properties/text", ["exports", "lightway-connect/validations/pages/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_text.default, {
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    classNames: ['current-part-settings'],
    withinDynamicContainer: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer;
    }),
    subject: Ember.computed('model.currentDynamicContainer', function () {
      return this.model.currentDynamicContainer.subject;
    }),
    subjectType: Ember.computed('model.currentDynamicContainer.preferences.subject-type', function () {
      var result;

      if (this.model.currentDynamicContainer.isA('page-version')) {
        var subjectType = this.model.currentDynamicContainer.get('preferences.subject-type');

        if (subjectType && subjectType != {}) {
          this.store.peekAll(subjectType.type).forEach(function (itm) {
            if (itm.id == subjectType.id) {
              result = itm;
            }
          });
        }
      } else if (this.model.currentDynamicContainer) {
        if (this.model.currentDynamicContainer.isA('data-kind')) {
          return this.model.currentDynamicContainer;
        }

        var subjectTypeId = this.model.currentDynamicContainer.get('preferences.subject-type.id');
        var subjectTypeType = this.model.currentDynamicContainer.get('preferences.subject-type.type');

        if (!subjectTypeId) {
          if (this.model.parent.parent.parent) {
            subjectTypeId = this.model.parent.parent.parent.get('preferences.subject-type.id');
            subjectTypeType = this.model.parent.parent.parent.get('preferences.subject-type.type');
          }
        }

        if (subjectTypeType && subjectTypeId) {
          result = this.store.peekRecord(subjectTypeType, subjectTypeId);
        }
      }

      return result;
    }),
    dynamicProperties: Ember.computed('subjectType', function () {
      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.editorService.inKindMode || this.editorService.inItemMode || this.editorService.inEssentialContentItemMode) {
        var kindId = this.model.currentDynamicContainer.id;
        var kind = this.store.peekRecord('data-kind', kindId);
        var fields = Ember.A([]);
        kind.fields.forEach(function (field) {
          if (field.kind != 'association-field') {
            fields.addObject(field);
          }
        });
        return fields;
      }

      if (this.model.currentDynamicContainer.get('preferences.dynamic') === "collection") {
        var type = this.model.currentDynamicContainer.get('preferences.subject-type.type');

        if (type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name"
          }]);
        } else if (type == 'data-kind') {
          var _kindId = this.model.currentDynamicContainer.get('preferences.subject-type.id');

          var _kind = this.store.peekRecord('data-kind', _kindId);

          var _fields = Ember.A([]);

          _kind.fields.forEach(function (field) {
            if (field.kind != 'association-field') {
              _fields.addObject(field);
            }
          });

          return _fields;
        }
      }

      if (this.model.currentDynamicContainer.get('preferences.dynamic') == "grouped-collection") {
        return Ember.A([{
          id: "month",
          label: "Month",
          type: "property"
        }, {
          id: "year",
          label: "Year",
          type: "property"
        }, {
          id: "month-year",
          label: "Month & Year",
          type: "property"
        }]);
      }

      if (this.subjectType) {
        if (this.subjectType.type == "pages") {
          return Ember.A([{
            id: "name",
            label: "Name",
            type: "property"
          }]);
        } else {
          var _fields2 = Ember.A([]);

          if (this.model.withinListItemContainer && this.model.subjectTypeObject) {
            this.model.subjectTypeObject.fields.forEach(function (field) {
              if (field.kind != 'association-field') {
                _fields2.addObject(field);
              }
            });
            return _fields2;
          }

          this.subjectType.fields.forEach(function (field) {
            if (field.kind != 'association-field') {
              _fields2.addObject(field);
            }
          });
          return _fields2;
        }
      } else if (this.subject) {
        var _fields3 = Ember.A([]);

        this.subject.parent.fields.forEach(function (field) {
          if (field.kind != 'association-field') {
            _fields3.addObject(field);
          }
        });
        return _fields3;
      }

      return null;
    }),
    dynamicAssociations: Ember.computed('subjectType', function () {
      var _this = this;

      if (!this.withinDynamicContainer) {
        return null;
      }

      if (this.subjectType) {
        var associations = Ember.A([]); //       if (this.subjectType.isA('part')) {
        //         this.subjectType.subjectTypeObject.allFields.forEach((field) => {
        //           if (field.kind === 'association-field') {
        //
        //             // If reverse field
        //             if (field.subjectKind != this.model.parent) {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: true
        //               })
        //             } else {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: false
        //               })
        //             }
        //
        //
        //           }
        //         });
        //       } else {
        //         this.subjectType.allFields.forEach((field) => {
        //           if (field.kind === 'association-field') {
        //
        //             // If reverse field
        //             if (field.subjectKind != this.model.parent) {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: true
        //               })
        //             } else {
        //               associations.addObject({
        //                 field: field,
        //                 reverse: false
        //               })
        //             }
        //
        //
        //           }
        //         });
        //       }

        return associations;
      } else if (this.subject) {
        var _associations = Ember.A([]);

        this.subject.parent.fields.forEach(function (field) {
          if (field.kind == 'association-field') {
            // If reverse field
            if (field.subjectKind != _this.model.parent) {
              _associations.addObject({
                field: field,
                reverse: true
              });
            } else {
              _associations.addObject({
                field: field,
                reverse: false
              });
            }
          }
        });
        return _associations;
      }

      return null;
    }),
    dynamicTextValue: Ember.computed('model.preferences.dynamic-value', function () {
      var result = "";
      var dVHash = this.model.get('preferences.dynamic-value');

      if (dVHash && dVHash.type && dVHash.id) {
        // if (dVHash['association-field-id']) {
        //   let associationField = this.store.peekRecord(
        //     'data-field', dVHash['association-field-id']);
        //   if (associationField) {
        //     result += associationField.label + " > "
        //   }
        // }
        if (dVHash.type == "data-field") {
          var field = this.store.peekRecord(dVHash.type, dVHash.id);

          if (field) {
            result += field.label;
          }
        } else if (dVHash.type == "property") {
          result += dVHash.id;
        }
      }

      return result;
    }),
    dynamicTextValueIsDate: Ember.computed('dynamicTextValue', function () {
      if (this.dynamicTextValue === "" || !this.dynamicTextValue) {
        return false;
      }

      var dVHash = this.model.get('preferences.dynamic-value');

      if (dVHash && dVHash.type && dVHash.id) {
        if (dVHash.type == "data-field") {
          var field = this.store.peekRecord(dVHash.type, dVHash.id);

          if (field && field.kind === "date-field") {
            return true;
          }
        }
      }

      return false;
    }),
    actions: {
      toggleModalForValue: function toggleModalForValue() {
        this.toggleProperty('selectingValue');
      },
      cancelValueSelection: function cancelValueSelection() {
        this.set('selectingValue', false);
      },
      selectValue: function selectValue(value, associationField) {
        var dynamicValue;

        if (this.model.currentDynamicContainer.get('preferences.dynamic') == "collection" && this.model.currentDynamicContainer.get('preferences.subject-type.type') == "pages") {
          dynamicValue = dynamicValue = {
            type: "property",
            id: value.id
          };
        } else {
          if (value.id === "month-year") {
            dynamicValue = {
              type: "property",
              id: value.id
            };
          } else {
            dynamicValue = {
              type: "data-field",
              id: value.id
            };
          }

          if (associationField) {
            dynamicValue['association-field-id'] = associationField.id;
          }
        }

        var changes = {};
        changes["preferences.dynamic-value"] = dynamicValue;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.set('selectingValue', false);
      },
      removeValue: function removeValue() {
        var changes = {};
        changes["preferences.dynamic-value"] = null;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }
    }
  });

  _exports.default = _default;
});