define("lightway-connect/components/content-editor/part-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    hoverItem: Ember.computed('part', 'editorService.{selection,hoverSelection}', function () {
      var _this = this;

      var result = null;

      if (!this.part.isA("layout-version")) {
        this.part.hoverItems.forEach(function (hoverItem) {
          if (hoverItem.owner == _this.editorService.hoverSelection) {
            result = hoverItem;
          }
        });
      }

      return result;
    }),
    cssString: Ember.computed('widthString', 'heightString', 'minWidthString', 'minHeightString', 'marginString', 'paddingString', 'borderString', 'borderColorString', 'radiusString', 'backgroundColorString', 'backgroundGradientString', 'backgroundImageString', 'textCss', 'part.{parent.contentDirection,identifier}', function () {
      var result = "";

      if (this.part.isA("layout-version")) {
        result += ".canvas-parts-grid{" + this.backgroundColorString + this.backgroundGradientString + this.backgroundImageString + "}";
        result += this.textCss;
      }

      var pBpSelector = ""; // For overrides

      if (this.prototypeBasedPart) {
        pBpSelector += ".ps".concat(this.prototypeBasedPart.identifier, " "); // Trailing space important for selector nesting!
      }

      if (this.part.kind === "button") {
        result += ".pt".concat(this.part.identifier, " button{") + this.paddingString + this.borderString + this.borderColorString + this.marginString + this.backgroundColorString + this.backgroundGradientString + this.backgroundImageString + this.radiusString + "}";
        return result;
      }

      result += ".p".concat(this.part.identifier, "{") + this.widthString + this.wHeightString + this.paddingString + this.positionString + '}' + ".w".concat(this.part.identifier, "{") + this.widthString + this.pHeightString + '}';

      if (this.part.parent && this.part.parent.contentDirection == 'horizontal') {
        result += ".ps".concat(this.part.identifier, "{") + this.borderString + this.borderColorString + this.marginString + "}".concat(pBpSelector, ".pt").concat(this.part.identifier, "{") + this.widthString + this.heightString + this.backgroundColorString + this.backgroundGradientString + this.backgroundImageString + this.radiusString + '}';
      } else {
        result += ".ps".concat(this.part.identifier, "{") + this.marginString + "}".concat(pBpSelector, ".pt").concat(this.part.identifier, "{") + this.borderString + this.borderColorString + this.widthString + this.heightString + this.backgroundColorString + this.backgroundGradientString + this.backgroundImageString + this.radiusString + '}';
      }

      result += ".pt".concat(this.part.identifier, " .inner-cell{") + this.innerCellOffset + '}' + this.textCss + ".o".concat(this.part.identifier, "{") + this.minWidthString + this.minHeightString + '}';
      return result;
    }),
    marginString: Ember.computed('part.margin', function () {
      var margin = this.calculatedValue('margin');

      if (margin) {
        return "margin:" + margin + ";";
      } else {
        var value = this.directionalMeasurementFor('margin');

        if (value != "") {
          return "margin:" + value;
        } else {
          return "";
        }
      }
    }),
    paddingString: Ember.computed('part.padding', function () {
      var padding = this.calculatedValue('padding');

      if (padding) {
        return "padding:" + padding + ";";
      } else {
        var value = this.directionalMeasurementFor('padding');

        if (value != "") {
          return "padding:" + value;
        } else {
          return "";
        }
      }
    }),
    positionString: Ember.computed('part.{height,heightType}', function () {
      if (this.part.heightType == 'aspect-ratio') {
        return "position:absolute;top:0;right:0;bottom:0;left:0;";
      } else {
        return "";
      }
    }),
    borderString: Ember.computed('part.{border,inheritedBorder}', function () {
      var border = this.calculatedValue('border');

      if (border) {
        return "border-width:" + border + ";border-style:solid;";
      } else {
        var value = this.directionalMeasurementFor('border');

        if (value != "") {
          return "border-width:" + value + "border-style:solid;";
        } else {
          return "";
        }
      }
    }),
    borderColorString: Ember.computed('part.{borderColorType,borderColorValue,inheritedBorderColorType,inheritedBorderColorValue}', 'hoverItem.{borderColorType,borderColorValue,inheritedBorderColorType,inheritedBorderColorValue}', function () {
      var colorType = this.calculatedValue('borderColorType');
      var colorValue = this.calculatedValue('borderColorValue');

      if (colorType == 'custom') {
        if (!colorValue) {
          return "";
        }

        return "border-color:" + colorValue + ";";
      } else if (colorType == 'global-color') {
        if (!colorValue) {
          return "";
        }

        if (!this.editorService.globalStyle) {
          return "";
        }

        var gColor = this.get("editorService.canvasVersion.globalStyle.styleProperties.".concat(colorValue, ".value"));
        return "border-color:" + gColor + ";";
      } else if (colorType == 'color-class') {
        if (!colorValue) {
          return "";
        }

        if (!this.editorService.globalStyle) {
          return "";
        }

        var colorClass = this.get("editorService.canvasVersion.globalStyle.styleProperties.".concat(colorValue, ".value"));
        return "border-color:" + colorClass + ";";
      } else {
        return "";
      }
    }),
    radiusString: Ember.computed('part.{borderRadius,inheritedBorderRadius}', function () {
      var radius = this.calculatedValue('borderRadius');

      if (radius) {
        return "border-radius:" + radius + ";";
      } else {
        var value = this.cornerMeasurementFor('border-radius');

        if (value != "") {
          return "border-radius:" + value + ";";
        } else {
          return "";
        }
      }
    }),
    containerWidth: Ember.computed('part.{widthType,width,inheritedWidthType,inheritedWidth}', 'hoverItem.{widthType,width,inheritedWidthType,inheritedWidth}', function () {
      var widthType = this.calculatedValue('widthType');

      if (widthType == 'fill') {
        return "";
      }

      if (widthType == 'fixed') {
        return this.calculatedValue('width');
      }

      return null;
    }),
    widthString: Ember.computed('containerWidth', function () {
      if (this.part.kind == 'image') {
        var widthType = this.calculatedValue('widthType');
        var width = this.calculatedValue('width');

        if (widthType == 'fixed') {
          return "width:".concat(width, ";");
        } else if (widthType == 'fill') {
          return "width:100%;";
        } else if (this.part.kontent) {
          return "width:".concat(this.part.kontent.width, "px;");
        }
      }

      if (this.containerWidth && this.containerWidth != "") {
        return "width:" + this.containerWidth + ";"; //" !important;"
      } else {
        return "";
      }
    }),
    minWidthString: Ember.computed('containerWidth', function () {
      if (this.containerWidth && this.containerWidth != "") {
        return "min-width:" + this.containerWidth + ";";
      } else {
        return "";
      }
    }),
    containerHeight: Ember.computed('part.{heightType,height,inheritedHeightType,inheritedHeight}', 'hoverItem.{heightType,height,inheritedHeightType,inheritedHeight}', function () {
      var heightType = this.calculatedValue('heightType');
      var height = this.calculatedValue('height');

      if (heightType == 'fill') {
        // !!IMPORTANT!!
        // If part is a root level container, meaning it is to at minimum fill
        // the screen but also extend its height to the height of its nested parts,
        // height needs to be 100%. If not, min-height needs to be auto.
        if (this.part.parent.isA('layout-version')) {
          return "100%";
        } else {
          return "auto";
        }
      }

      if (heightType == 'vh') {
        // If part is root level, return nothing so editor page still scrolls.
        // Otherwise give the viewport height a dummy value.
        if (!this.part.parent.isA('layout-version')) {
          return "800px";
        }
      }

      if (heightType == 'fixed') {
        return height;
      } else {
        return "";
      }
    }),
    heightString: Ember.computed('containerHeight', function () {
      if (this.calculatedValue('heightType') == 'fill') {
        return "height:100%;";
      } else {
        if (this.calculatedValue('heightType') == 'fixed') {
          return "min-height:".concat(this.calculatedValue('height'), ";");
        }

        if (this.calculatedValue('heightType') == 'aspect-ratio') {
          var height = this.calculatedValue('height');

          if (height) {
            height = height.split(':');
            var percentage = height.get('lastObject') / height.get('firstObject') * 100; // let percentage = 9/16*100

            return "padding-bottom:".concat(percentage, "%;");
          } else {
            return "";
          }
        }

        if (this.containerHeight && this.containerHeight != "") {
          if (this.containerHeight == 'auto') {
            return "min-height:auto;";
          } else {
            return "height:" + this.containerHeight + ";";
          }
        } else {
          if (this.calculatedValue('heightType') == 'vh') {
            // return ""
            return "height:600px;";
          } else {
            return "min-height:10px;";
          }
        }
      }
    }),
    wHeightString: Ember.computed('containerHeight', function () {
      if (this.calculatedValue('heightType') == 'fill') {
        return "height:100%;";
      } else {
        if (this.containerHeight && this.containerHeight != "") {
          if (this.containerHeight == 'auto') {
            return "min-height:auto;";
          } else {
            return "height:" + this.containerHeight + ";";
          }
        } else {
          return "";
        }
      }
    }),
    pHeightString: Ember.computed('containerHeight', function () {
      if (this.calculatedValue('heightType') == 'fill') {
        return "height:100%;";
      } else {
        if (this.containerHeight && this.containerHeight != "") {
          if (this.containerHeight == '100%') {
            return "height:100%;";
          } else {
            return "height:" + this.containerHeight + ";";
          }
        } else {
          return "min-height:10px;";
        }
      }
    }),
    minHeightString: Ember.computed('containerHeight', function () {
      if (this.containerHeight && this.containerHeight != "") {
        return "min-height:" + this.containerHeight + ";";
      } else {
        return "";
      }
    }),
    innerCellOffset: Ember.computed(function () {
      return "outline-offset:0;";
    }),
    backgroundColorString: Ember.computed('part.{backgroundColorType,backgroundColorValue,inheritedBackgroundColorType,inheritedBackgroundColorValue}', 'hoverItem.{backgroundColorType,backgroundColorValue,inheritedBackgroundColorType,inheritedBackgroundColorValue}', function () {
      var colorType = this.calculatedValue('backgroundColorType');
      var colorValue = this.calculatedValue('backgroundColorValue');
      var colorVal = this.finalColorValue(colorType, colorValue);

      if (!colorValue) {
        return "";
      }

      return "background-color:" + colorVal + ";";
    }),
    backgroundGradientString: Ember.computed('part.{backgroundGradientDirection,backgroundGradientAColorType,backgroundGradientAColorValue,inheritedbackgroundGradientAColorType,inheritedbackgroundGradientAColorValue,backgroundGradientBColorType,backgroundGradientBColorValue,inheritedbackgroundGradientBColorType,inheritedbackgroundGradientBColorValue}', 'hoverItem.{backgroundGradientDirection,backgroundGradientAColorType,backgroundGradientAColorValue,inheritedbackgroundGradientAColorType,inheritedbackgroundGradientAColorValue,backgroundGradientBColorType,backgroundGradientBColorValue,inheritedbackgroundGradientBColorType,inheritedbackgroundGradientBColorValue}', function () {
      // TODO
      var bgGradDirection = this.calculatedValue('backgroundGradientDirection');
      var bgGradAColorType = this.calculatedValue('backgroundGradientAColorType');
      var bgGradAColorValue = this.calculatedValue('backgroundGradientAColorValue');
      var bgGradBColorType = this.calculatedValue('backgroundGradientBColorType');
      var bgGradBColorValue = this.calculatedValue('backgroundGradientBColorValue'); // Calculate final color values
      // let aValue
      // if (bgGradAColorType && bgGradAColorType == 'custom') {
      //   aValue = bgGradAColorValue
      // } else if (bgGradAColorType && bgGradAColorType == 'reference') {
      //   aValue = this.get(`editorService.canvasVersion.globalStyle.styleProperties.${bgGradAColorReference}.value`)
      // }

      var aValue = this.finalColorValue(bgGradAColorType, bgGradAColorValue);

      if (!aValue) {
        return "";
      }

      var bValue = this.finalColorValue(bgGradBColorType, bgGradBColorValue);

      if (!bValue) {
        return "";
      } // let bValue
      // if (bgGradBColorType && bgGradBColorType == 'custom') {
      //   bValue = bgGradBColorValue
      // } else if (bgGradBColorType && bgGradBColorType == 'reference') {
      //   bValue = this.get(`editorService.canvasVersion.globalStyle.styleProperties.${bgGradBColorReference}.value`)
      // }
      // if (!bValue) { return "" }
      // Start string
      // e.g. "background-image: linear-gradient(to right, red, yellow);"


      var gradientString = "background-image: linear-gradient("; // Add direction

      switch (bgGradDirection) {
        case 'left-to-right':
          gradientString += "to right, ";
          break;

        case 'top-to-bottom':
          gradientString += "to bottom, ";
          break;

        case 'top-left-to-bottom-right':
          gradientString += "to bottom right, ";
          break;

        case 'bottom-left-to-top-right':
          gradientString += "to top right, ";
          break;

        default:
          gradientString += "to right, ";
      } // Add colors


      gradientString += aValue + ", " + bValue + ");";
      return gradientString;
    }),
    backgroundImageString: Ember.computed('previewObject', function () {
      var result = "";

      if (this.previewObject && this.previewObject['background-image-reference']) {
        var image = this.store.peekRecord('image', this.previewObject['background-image-reference']);

        if (image) {
          result = "background-image:url('" + image.imageUrl + "');";
        }

        if (this.previewObject['background-size']) {
          result += "background-size:".concat(this.previewObject['background-size'], ";");
        }

        if (this.previewObject['background-repeat']) {
          result += "background-repeat:".concat(this.previewObject['background-repeat'], ";");
        }

        if (this.previewObject['background-position']) {
          result += "background-position:".concat(this.previewObject['background-position'], ";");
        }
      }

      return result;
    }),
    textCss: Ember.computed('textString', 'pString', 'h1String', 'h2String', 'h3String', 'h4String', 'h5String', 'h6String', 'blockquoteString', function () {
      var finalString = this.textString + this.pString + this.h1String + this.h2String + this.h3String + this.h4String + this.h5String + this.h6String + this.blockquoteString;
      return finalString;
    }),
    textString: Ember.computed('part.{identifier,textFontReference,textSize,textAlign,textWeight,textColorType,textColorValue}', 'hoverItem.{identifier,textFontReference,textSize,textAlign,textWeight,textColorType,textColorValue}', function () {
      return this.cssStringFor('text');
    }),
    pString: Ember.computed('part.{identifier,pFontReference,pSize,pAlign,pWeight,pColorType,pColorValue,pCase}', 'hoverItem.{identifier,pFontReference,pSize,pAlign,pWeight,pColorType,pColorValue,pCase}', function () {
      return this.cssStringFor('p');
    }),
    h1String: Ember.computed('part.{identifier,h1FontReference,h1Size,h1Align,h1Weight,h1ColorType,h1ColorValue}', 'hoverItem.{identifier,h1FontReference,h1Size,h1Align,h1Weight,h1ColorType,h1ColorValue}', function () {
      return this.cssStringFor('h1');
    }),
    h2String: Ember.computed('part.{identifier,h2FontReference,h2Size,h2Align,h2Weight,h2ColorType,h2ColorValue}', 'hoverItem.{identifier,h2FontReference,h2Size,h2Align,h2Weight,h2ColorType,h2ColorValue}', function () {
      return this.cssStringFor('h2');
    }),
    h3String: Ember.computed('part.{identifier,h3FontReference,h3Size,h3Align,h3Weight,h3ColorType,h3ColorValue}', 'hoverItem.{identifier,h3FontReference,h3Size,h3Align,h3Weight,h3ColorType,h3ColorValue}', function () {
      return this.cssStringFor('h3');
    }),
    h4String: Ember.computed('part.{identifier,h4FontReference,h4Size,h4Align,h4Weight,h4ColorType,h4ColorValue}', 'hoverItem.{identifier,h4FontReference,h4Size,h4Align,h4Weight,h4ColorType,h4ColorValue}', function () {
      return this.cssStringFor('h4');
    }),
    h5String: Ember.computed('part.{identifier,h5FontReference,h5Size,h5Align,h5Weight,h5ColorType,h5ColorValue}', 'hoverItem.{identifier,h5FontReference,h5Size,h5Align,h5Weight,h5ColorType,h5ColorValue}', function () {
      return this.cssStringFor('h5');
    }),
    h6String: Ember.computed('part.{identifier,h6FontReference,h6Size,h6Align,h6Weight,h6ColorType,h6ColorValue}', 'hoverItem.{identifier,h6FontReference,h6Size,h6Align,h6Weight,h6ColorType,h6ColorValue}', function () {
      return this.cssStringFor('h6');
    }),
    blockquoteString: Ember.computed('part.{identifier,blockquoteFontReference,blockquoteSize,blockquoteAlign,blockquoteWeight,blockquoteColorType,blockquoteColorValue}', 'hoverItem.{identifier,blockquoteFontReference,blockquoteSize,blockquoteAlign,blockquoteWeight,blockquoteColorType,blockquoteColorValue}', function () {
      return this.cssStringFor('blockquote');
    }),
    finalColorValue: function finalColorValue(colorType, colorValue) {
      if (colorType == 'custom') {
        if (!colorValue) {
          return "";
        }

        return colorValue;
      } else if (colorType == 'global-color') {
        if (!colorValue) {
          return "";
        }

        return this.globalPaletteColorById(colorValue).value;
      } else if (colorType == 'color-class') {
        if (!colorValue) {
          return "";
        }

        var colorIndex;
        var colorClassIDs = this.editorService.canvasVersion.colorClassIDs;

        if (colorClassIDs) {
          this.editorService.canvasVersion.colorClassIDs.forEach(function (cid, i) {
            if (cid === colorValue) {
              colorIndex = i;
            }
          });
        }

        if (colorIndex != null) {
          var colorClass = this.get("editorService.canvasVersion.colorClasses.".concat(colorIndex));

          if (colorClass.type === "global-color") {
            return this.globalPaletteColorById(colorClass.value).value;
          }

          return colorClass.value;
        }
      }

      return "";
    },
    globalPaletteColorById: function globalPaletteColorById(id) {
      var colorIndex;
      this.get("currentSite.site.globalPalette.styleProperties.color-ids").forEach(function (cid, i) {
        if (cid === id) {
          colorIndex = i;
        }
      });
      var colorClass = this.get("currentSite.site.globalPalette.styleProperties.colors.".concat(colorIndex));
      return colorClass;
    },
    cssStringFor: function cssStringFor(type) {
      var fontFamilyString = this.fontFamilyFor(type);
      var fontSizeString = this.fontSizeFor(type);
      var fontAlignString = this.fontAlignFor(type);
      var fontWeightString = this.fontWeightFor(type);
      var colorString = this.fontColorFor(type);
      var caseString = this.fontCaseFor(type); // Combine all strings

      var combinedStrings = fontFamilyString + fontSizeString + fontAlignString + fontWeightString + colorString + caseString;

      if (combinedStrings == "") {
        return "";
      }

      if (type == "text") {
        if (this.part.isA("layout-version")) {
          return ".canvas-parts-grid, " + ".canvas-parts-grid .ql-editor, " + ".canvas-parts-grid .ql-editor p, .canvas-parts-grid p, " + ".canvas-parts-grid .ql-editor h1, .canvas-parts-grid h1, " + ".canvas-parts-grid .ql-editor h2, .canvas-parts-grid h2, " + ".canvas-parts-grid .ql-editor h3, .canvas-parts-grid h3, " + ".canvas-parts-grid .ql-editor h4, .canvas-parts-grid h4, " + ".canvas-parts-grid .ql-editor h5, .canvas-parts-grid h5, " + ".canvas-parts-grid .ql-editor h6, .canvas-parts-grid h6, " + ".canvas-parts-grid .ql-editor blockquote, .canvas-parts-grid blockquote" + "{".concat(combinedStrings, "}");
        } else {
          return ".pt".concat(this.part.identifier, ", ") + ".pt".concat(this.part.identifier, " .ql-editor, ") + ".pt".concat(this.part.identifier, " .ql-editor p, .pt").concat(this.part.identifier, " p, ") + ".pt".concat(this.part.identifier, " .ql-editor h1, .pt").concat(this.part.identifier, " h1, ") + ".pt".concat(this.part.identifier, " .ql-editor h2, .pt").concat(this.part.identifier, " h2, ") + ".pt".concat(this.part.identifier, " .ql-editor h3, .pt").concat(this.part.identifier, " h3, ") + ".pt".concat(this.part.identifier, " .ql-editor h4, .pt").concat(this.part.identifier, " h4, ") + ".pt".concat(this.part.identifier, " .ql-editor h5, .pt").concat(this.part.identifier, " h5, ") + ".pt".concat(this.part.identifier, " .ql-editor h6, .pt").concat(this.part.identifier, " h6, ") + ".pt".concat(this.part.identifier, " .ql-editor blockquote, .pt").concat(this.part.identifier, " blockquote") + "{".concat(combinedStrings, "}");
        }
      }

      return ".pt".concat(this.part.identifier, " .ql-editor ").concat(type, "{").concat(combinedStrings, "}");
    },
    fontFamilyFor: function fontFamilyFor(type) {
      var fontFamilyString = "";
      var fontReference = this.calculatedValue('fontReference', type);

      if (fontReference) {
        if (!this.editorService.canvasVersion.globalStyle) {
          return "";
        }

        var gFont = this.get("editorService.canvasVersion.globalStyle.styleProperties.".concat(fontReference));

        if (!gFont) {
          return "";
        }

        fontFamilyString = "font-family:" + "\"" + gFont.value + "\"" + ", " + gFont.category + ";";
      }

      return fontFamilyString;
    },
    fontSizeFor: function fontSizeFor(type) {
      var fontSizeString = "";
      var size = this.calculatedValue('size', type);

      if (size) {
        fontSizeString = "font-size:".concat(size, ";");
      }

      return fontSizeString;
    },
    fontCaseFor: function fontCaseFor(type) {
      var fontCaseString = "";
      var fontCase = this.calculatedValue('case', type);

      if (fontCase) {
        fontCaseString = "text-transform:".concat(fontCase, ";");
      }

      return fontCaseString;
    },
    fontAlignFor: function fontAlignFor(type) {
      var alignString = "";
      var fontAlign = this.calculatedValue('align', type);

      if (fontAlign) {
        alignString = "text-align:".concat(fontAlign, ";");
      }

      return alignString;
    },
    fontWeightFor: function fontWeightFor(type) {
      var fontWeightString = "";
      var weight = this.calculatedValue('variant', type);

      if (weight) {
        if (weight.includes('i')) {
          weight = weight.replace('i', '');
          fontWeightString += "font-style:italic;";
        }

        fontWeightString += "font-weight:".concat(weight, ";");
      }

      return fontWeightString;
    },
    fontColorFor: function fontColorFor(type) {
      var colorType = this.calculatedValue('colorType', type);
      var colorValue = this.calculatedValue('colorValue', type);
      var value = this.finalColorValue(colorType, colorValue);

      if (!value) {
        return "";
      }

      return "color:" + value + ";";
    },
    calculatedValue: function calculatedValue(property, type) {
      // Style value
      var styleValue;
      var path;

      if (type) {
        path = type + Ember.String.capitalize(property);
      } else {
        path = Ember.String.camelize(property);
      }

      this.part.styles.forEach(function (style) {
        var sV = style.get(path); // Check for inherited style value

        if (sV) {
          styleValue = sV;
        } else {
          var iSV = style.get("inherited".concat(Ember.String.capitalize(path)));

          if (iSV) {
            styleValue = iSV;
          }
        }
      }); // Native value

      var nativeValue = this.valueFor(path, "inherited".concat(Ember.String.capitalize(path)));

      if (nativeValue) {
        return nativeValue;
      } else {
        return styleValue;
      }
    },
    valueFor: function valueFor(property, inheritedProperty) {
      var result = this.part.get(property) || this.part.get(inheritedProperty);

      if (this.hoverItem && this.hoverItem.get(property)) {
        result = this.hoverItem.get(property);
      } else if (this.hoverItem && this.hoverItem.get(inheritedProperty)) {
        result = this.hoverItem.get(inheritedProperty);
      }

      return result;
    },
    directionalMeasurementFor: function directionalMeasurementFor(property) {
      var topValue = this.calculatedValue("".concat(property, "-top"));
      var rightValue = this.calculatedValue("".concat(property, "-right"));
      var bottomValue = this.calculatedValue("".concat(property, "-bottom"));
      var leftValue = this.calculatedValue("".concat(property, "-left"));
      var top;

      if (topValue) {
        top = topValue + " ";
      } else {
        top = "0 ";
      }

      var right;

      if (rightValue) {
        right = rightValue + " ";
      } else {
        right = "0 ";
      }

      var bottom;

      if (bottomValue) {
        bottom = bottomValue + " ";
      } else {
        bottom = "0 ";
      }

      var left;

      if (leftValue) {
        left = leftValue;
      } else {
        left = "0";
      }

      if (topValue || rightValue || bottomValue || leftValue) {
        return top + right + bottom + left + ";";
      } else {
        return "";
      }
    },
    cornerMeasurementFor: function cornerMeasurementFor(property) {
      var topLeftValue = this.calculatedValue("".concat(property, "-top-left"));
      var topRightValue = this.calculatedValue("".concat(property, "-top-right"));
      var bottomLeftValue = this.calculatedValue("".concat(property, "-bottom-left"));
      var bottomRightValue = this.calculatedValue("".concat(property, "-bottom-right"));
      var topLeft;

      if (topLeftValue) {
        topLeft = topLeftValue + " ";
      } else {
        topLeft = "0 ";
      }

      var topRight;

      if (topRightValue) {
        topRight = topRightValue + " ";
      } else {
        topRight = "0 ";
      }

      var bottomLeft;

      if (bottomLeftValue) {
        bottomLeft = bottomLeftValue + " ";
      } else {
        bottomLeft = "0 ";
      }

      var bottomRight;

      if (bottomRightValue) {
        bottomRight = bottomRightValue + " ";
      } else {
        bottomRight = "0 ";
      }

      if (topLeftValue || topRightValue || bottomLeftValue || bottomRightValue) {
        return topLeft + topRight + bottomLeft + bottomRight + ";";
      } else {
        return "";
      }
    }
  });

  _exports.default = _default;
});