define("lightway-connect/components/editor/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="grid-x content-canvas-grid-small">
    <div role="button" {{action @deselectAction}} class="cell small-12 canvas-divider" data-test-id="deselect-all"></div>
  </div>
  
  <div class="grid-x content-canvas-grid-small">
    <div role="button" {{action @deselectAction}} class="cell auto"></div>
  
    <div class="cell canvas-cell content-canvas-small">
  
      <div class="grid-x full-height canvas-blocks-grid">
        <div class="cell small-12 canvas-body" id={{@editorJsHolder}} data-test-id="canvas-body"></div>
      </div>
  
    </div>
  
    <div role="button" {{action @deselectAction}} class="cell auto"></div>
  </div>
  
  <div class="grid-x content-canvas-grid-small">
    <div role="button" {{action @deselectAction}} class="cell small-12 canvas-divider" data-test-id="deselect-all"></div>
  </div>
  
  {{yield}}
  
  */
  {
    id: "A+C2gAU+",
    block: "{\"symbols\":[\"@deselectAction\",\"@editorJsHolder\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"grid-x content-canvas-grid-small\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell small-12 canvas-divider\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x content-canvas-grid-small\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell auto\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"cell canvas-cell content-canvas-small\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height canvas-blocks-grid\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell small-12 canvas-body\"],[11,\"id\",[23,2,[]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell auto\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x content-canvas-grid-small\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell small-12 canvas-divider\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/editor/canvas.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});