define("lightway-connect/templates/sites/site/web-forms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4+XmpbI/",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container full\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top-bar section-head\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"top-bar-left\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[0,\"\\n          Forms\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"top-bar-right\"],[8],[0,\"\\n        \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"create_web_forms\"],null]]],{\"statements\":[[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"new-resource\"],[8],[0,\"\\n            \"],[5,\"link-to\",[],[[\"@route\"],[\"sites.site.web-forms.new\"]],{\"statements\":[[0,\"\\n              New Web Form\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"if-permitted\",[],[[\"@permissions\"],[[28,\"arr\",[\"view_web_forms\",\"update_web_forms\",\"destroy_web_forms\",\"create_web_form_fields\",\"update_web_form_fields\",\"destroy_web_form_fields\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"paginated-list\",[],[[\"@items\",\"@navRoute\"],[[23,1,[]],\"sites.site.web-forms\"]],{\"statements\":[[0,\"\\n\\n        \"],[5,\"sortable-list-menu\",[],[[\"@class\",\"@navRoute\",\"@itemRoute\",\"@itemKind\",\"@items\",\"@saveItemAction\",\"@deleteRoute\"],[\"grid-x resource-list\",\"sites.site.web-forms\",\"sites.site.web-forms.web-form\",\"web-forms\",[23,1,[]],[28,\"action\",[[23,0,[]],\"save\"],null],\"sites.site.web-forms.web-form.delete\"]]],[0,\"\\n\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/web-forms/index.hbs"
    }
  });

  _exports.default = _default;
});