define("lightway-connect/components/sites/parts/pending-image-part/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s5TnRtmO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pending-image-part selected-part part\"],[8],[0,\"\\n  Select image from the sidebar\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/parts/pending-image-part/template.hbs"
    }
  });

  _exports.default = _default;
});