define("lightway-connect/components/sites/data/items/image-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    browsingImages: false,
    imageFolders: null,
    selectedFolder: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var imageFolders = this.store.query('image-folder', {
        filter: {
          site: this.get('currentSite.site.id'),
          parent: this.get('currentSite.site.id'),
          parent_type: 'sites',
          id: !null
        }
      });
      this.set('imageFolders', imageFolders);

      if (this.property != null) {
        if (this.property.get('image.id')) {
          this.set('selectionComplete', true);
        }
      }
    },
    actions: {
      selectFolder: function selectFolder(folder) {
        this.set('selectedFolder', folder);
      },
      beginAddingImage: function beginAddingImage() {
        this.set('browsingImages', true);
      },
      selectImage: function selectImage(image) {
        // Set property's image
        this.property.set('image', image); // Reinstate property from removedProperties if needed
        //

        this.set('selectedFolder', null);
        this.set('browsingImages', false);
        this.set('selectionComplete', true);
      },
      removeImage: function removeImage() {
        // Move property to removed-properties
        var item = this.property.get('item');
        item.get('removedProperties').pushObject(this.property);
        this.set('selectionComplete', false);
        this.set('browsingImages', false);
      }
    }
  });

  _exports.default = _default;
});