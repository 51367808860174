define("lightway-connect/mixins/style-parentable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    styles: (0, _model.hasMany)({
      inverse: 'parent',
      async: false
    }),
    removedStyles: (0, _model.hasMany)('style', {
      inverse: 'formerParent',
      async: false
    })
  });

  _exports.default = _default;
});