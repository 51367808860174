define("lightway-connect/components/content-editor/parts/container-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    dragSort: Ember.inject.service(),
    tagName: '',
    dynamicContentClass: Ember.computed('this.part.preferences.dynamic', function () {
      if (this.part.preferences.dynamic) {
        return "dynamic-content";
      } else {
        return "";
      }
    }),
    listGroup: Ember.computed('editorService.inPageMode', 'part.baseParent', function () {
      if (this.editorService.inPageMode) {
        if (!this.part.baseParent) {
          return "";
        }

        if (this.part.baseParent.isA('item-region')) {
          return "nested-group";
        } else {
          return "restricted";
        }
      }

      return "nested-group";
    })
  });

  _exports.default = _default;
});