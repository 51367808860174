define("lightway-connect/components/content-editor/parts/list-item-container-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="part-content fill-height p{{@part.identifier}} dynamic-content">
  
    {{#if @prototypeBasedPart}}
  
      <ContentEditor::DraggablePartsList
        @part={{@part}}
        @items={{@part.partsSortedBound}}
        @orientationOverride={{@orientationOverride}}
        @group="nested-group"
        @prototypeBasedPart={{@prototypeBasedPart}}
        @loopDynamicContainers={{@loopDynamicContainers}}
        @loopItem={{@loopItem}}
        @draggingEnabled={{true}}
        @selectPartAction={{@selectPartAction}}
        @dropPartAction={{@dropPartAction}} />
  
    {{else}}
  
      <ContentEditor::DraggablePartsList
        @part={{@part}}
        @items={{@part.partsSortedBound}}
        @orientationOverride={{@orientationOverride}}
        @group="nested-group"
        @prototypeBasedPart={{@prototypeBasedPart}}
        @loopDynamicContainers={{@loopDynamicContainers}}
        @loopItem={{@loopItem}}
        @draggingEnabled={{true}}
        @selectPartAction={{@selectPartAction}}
        @dropPartAction={{@dropPartAction}} />
  
    {{/if}}
  
  </div>
  
  {{yield}}
  
  */
  {
    id: "Izn4/DZi",
    block: "{\"symbols\":[\"@part\",\"@orientationOverride\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@selectPartAction\",\"@dropPartAction\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"part-content fill-height p\",[23,1,[\"identifier\"]],\" dynamic-content\"]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"\\n    \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,1,[]],[23,1,[\"partsSortedBound\"]],[23,2,[]],\"nested-group\",[23,3,[]],[23,4,[]],[23,5,[]],true,[23,6,[]],[23,7,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[5,\"content-editor/draggable-parts-list\",[],[[\"@part\",\"@items\",\"@orientationOverride\",\"@group\",\"@prototypeBasedPart\",\"@loopDynamicContainers\",\"@loopItem\",\"@draggingEnabled\",\"@selectPartAction\",\"@dropPartAction\"],[[23,1,[]],[23,1,[\"partsSortedBound\"]],[23,2,[]],\"nested-group\",[23,3,[]],[23,4,[]],[23,5,[]],true,[23,6,[]],[23,7,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,8],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/content-editor/parts/list-item-container-part.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});