define("lightway-connect/templates/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GX/8Iz+9",
    "block": "{\"symbols\":[],\"statements\":[[5,\"header\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x full-height\"],[8],[0,\"\\n    \"],[5,\"layouts/main-full\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell small-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"grid-x align-center\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"cell small-12 medium-8 large-6 card\"],[8],[0,\"\\n            \"],[7,\"h3\",true],[8],[0,\"Sign In\"],[9],[0,\"\\n\\n            \"],[5,\"accounts/sign-in-form\",[],[[],[]]],[0,\"\\n\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sign-in.hbs"
    }
  });

  _exports.default = _default;
});