define("lightway-connect/mixins/kontent-partable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    kontent: (0, _model.belongsTo)('partKontentable', {
      inverse: 'contentParts',
      polymorphic: true,
      async: false
    })
  });

  _exports.default = _default;
});