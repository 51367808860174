define("lightway-connect/templates/components/form-button-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iYuJqL9x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell small-6\"],[8],[0,\"\\n  \"],[1,[23,0,[\"action\",\"type\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"cell small-6\"],[3,\"action\",[[23,0,[]],\"removeAction\"]],[8],[0,\"\\n  Remove\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/form-button-action.hbs"
    }
  });

  _exports.default = _default;
});