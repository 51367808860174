define("lightway-connect/mixins/data-property-resource", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    resourceProperties: (0, _model.hasMany)('data-property', {
      inverse: 'resource'
    })
  });

  _exports.default = _default;
});