define("lightway-connect/components/color-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Default value
    // Set to white if no color value present
    value: "#FFFFFF",
    cssString: Ember.computed('value', function () {
      return ".".concat(this.colorId, "{") + 'background-color:' + this.value + '}';
    }),
    colorId: Ember.computed({
      get: function get() {
        if (this._colorId) {
          return this._colorId;
        }

        return 'color-' + Ember.guidFor(this);
      },
      set: function set(key, value) {
        return this._colorId = value;
      }
    })
  });

  _exports.default = _default;
});