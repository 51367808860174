define("lightway-connect/components/content-editor/image-collection-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function valueFromProperty(key, model, store) {
    return Ember.computed(key, "model.".concat(key), function () {
      var images = Ember.A([]);
      var val = Ember.get(model, "".concat(key));

      if (val) {
        val.forEach(function (item) {
          var image = store.findRecord('image', item.id);
          images.addObject(image);
        });
      }

      return images;
    });
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.defineProperty(this, 'currentImages', valueFromProperty(this.propertyPath, this.model, this.store));
    },
    inputId: Ember.computed({
      get: function get() {
        return Ember.guidFor(this) + '-inpudid';
      }
    }),
    propertyPath: Ember.computed(function () {
      return this.property;
    }),
    actions: {
      toggleModalForImages: function toggleModalForImages() {
        this.toggleProperty('isShowingModalForImages');
      },
      selectImages: function selectImages(images) {
        var imageArray = Ember.A([]); // Keep any images already existing in property

        if (this.model.get(this.propertyPath)) {
          this.model.get(this.propertyPath).forEach(function (item) {
            var imageHash = {
              "id": item.id
            };
            imageArray.addObject(imageHash);
          });
        }

        images.forEach(function (image) {
          var imageHash = {
            "id": image.id
          };
          imageArray.addObject(imageHash);
        });
        var changes = {};
        changes[this.propertyPath] = imageArray;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
        this.toggleProperty('isShowingModalForImages');
      },
      removeImage: function removeImage(image) {
        var imageArray = Ember.A([]);
        this.model.get(this.propertyPath).forEach(function (item) {
          if (item.id != image.id) {
            var imageHash = {
              "id": item.id
            };
            imageArray.addObject(imageHash);
          }
        });
        var changes = {};
        changes[this.propertyPath] = imageArray;
        var edit = this.editorService.prepareEdit(this.model, changes);
        this.editorService.addModifications(Ember.A([edit]));
      }
    }
  });

  _exports.default = _default;
});