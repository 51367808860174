define("lightway-connect/components/content-editor/part-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    label: Ember.computed(function () {
      if (Ember.typeOf(this.tool) == 'string') {
        switch (this.tool) {
          case 'cta':
            return "Call to Action Button";

          case 'image-gallery':
            return "Image Gallery";

          case 'data-profile':
            return "Data Profile";

          case 'region':
            return "Content Region";

          default:
            return this.tool.capitalize();
        }
      }

      return this.tool.name;
    }),
    actions: {
      desiredAction: function desiredAction() {
        switch (this.tool) {
          case 'section':
            this.addSection();
            break;

          case 'cta':
            this.addCta();
            break;

          case 'badge':
            this.addBadge();
            break;

          case 'content-clip':
            this.addContentClip();
            break;

          case 'divider':
            this.addDivider();
            break;

          case 'data-profile':
            this.addDataProfile();
            break;

          case 'data-collection':
            this.addDataCollection();
            break;

          case 'image-gallery':
            this.addImageGallery();
            break;

          case 'item-content':
            this.addItemContent();
            break;

          case 'container':
            this.addContainer();
            break;

          case 'list':
            this.addList();
            break;

          case 'text':
            this.addText();
            break;

          case 'image':
            this.addImage();
            break;

          case 'video':
            this.addVideo();
            break;

          case 'region':
            this.addRegion();
            break;

          case 'form':
            this.addForm();
            break;

          case 'text-field':
            this.addTextField();
            break;

          case 'password-field':
            this.addPasswordField();
            break;

          case 'button':
            this.addButton();
            break;
        }
      }
    }
  });

  _exports.default = _default;
});