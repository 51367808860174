define("lightway-connect/components/sites/media/move-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SHgWibvN",
    "block": "{\"symbols\":[\"folder\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"folders\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"folders\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"span\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectFolder\",[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/media/move-document/template.hbs"
    }
  });

  _exports.default = _default;
});