define("lightway-connect/components/sites/roles/delete-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zNriZIo6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"noContributors\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",\"button alert\"]],[[\"@route\",\"@model\"],[\"sites.site.settings.contributors.roles.role.delete\",[24,[\"role\",\"id\"]]]],{\"statements\":[[0,\"\\n    Delete\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/sites/roles/delete-link/template.hbs"
    }
  });

  _exports.default = _default;
});