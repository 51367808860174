define("lightway-connect/helpers/pagination-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function paginationQuery(params
  /*, hash*/
  ) {
    var key = params[0];
    var value = params[1];
    var hash = {};
    hash[key] = value;
    return hash;
  }); // first
  // last
  // previous
  // next


  _exports.default = _default;
});