define("lightway-connect/components/data/item-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    property: Ember.computed('item', 'field', function () {
      var _this = this;

      var property;
      this.item.properties.forEach(function (prop) {
        if (prop.field.id === _this.field.id) {
          property = prop;
        }
      });

      if (!property) {
        property = this.store.createRecord('data-property', {
          item: this.item,
          field: this.field
        });
      }

      return property;
    })
  });

  _exports.default = _default;
});