define("lightway-connect/templates/components/ui-form/currency-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "buEyUcJz",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[5,\"input\",[[12,\"placeholder\",[23,0,[\"placeholder\"]]],[12,\"onchange\",[28,\"action\",[[23,0,[]],\"updateProperty\"],[[\"value\"],[\"target.value\"]]]]],[[\"@id\",\"@type\",\"@value\",\"@size\",\"@maxlength\"],[[23,0,[\"inputId\"]],[23,0,[\"type\"]],[28,\"readonly\",[[28,\"get\",[[23,1,[]],[23,0,[\"property\"]]],null]],null],[23,0,[\"size\"]],[23,0,[\"maxlength\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"get\",[[23,1,[]],[23,0,[\"property\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"currency-preview\"],[8],[0,\"\\n    Displayed as \"],[1,[28,\"format-money\",[[28,\"hash\",null,[[\"amount\",\"currency\"],[[28,\"get\",[[23,1,[]],[23,0,[\"property\"]]],null],\"AUD\"]]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/ui-form/currency-input.hbs"
    }
  });

  _exports.default = _default;
});