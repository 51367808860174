define("lightway-connect/mixins/part-parentable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    parts: (0, _model.hasMany)({
      inverse: 'parent',
      async: false
    }),
    itemRegions: (0, _model.hasMany)({
      inverse: 'item',
      async: false
    }),
    removedParts: (0, _model.hasMany)('part', {
      inverse: 'formerParent',
      async: false
    }),
    inheritedParts: (0, _model.hasMany)('part', {
      inverse: 'parentInherited',
      async: false
    }),
    combinedParts: Ember.computed('parts.@each', 'inheritedParts.@each', function () {
      var pts = Ember.A([]);
      this.parts.forEach(function (pt) {
        pts.pushObject(pt);
      });
      this.inheritedParts.forEach(function (pt) {
        pts.pushObject(pt);
      });
      return pts;
    }),
    partsSortOrder: ['rowOrder:asc'],
    partsSorted: Ember.computed.sort('combinedParts', 'partsSortOrder'),
    partsSortedBound: Ember.computed('partsSorted.@each', function () {
      // Make a shallow copy of the array as CPs should never have side effects
      var parts = this.partsSorted.slice();
      parts.__parent__ = this;
      return parts;
    }),
    regionParts: Ember.computed('partsSorted.@each', function () {
      var regionParts = Ember.A([]);
      this.parts.forEach(function (pt) {
        if (pt.kind == "region") {
          regionParts.addObject(pt);
        }

        pt.regionParts.forEach(function (nestedPt) {
          regionParts.addObject(nestedPt);
        });
      });
      return regionParts;
    }),
    baseForm: Ember.computed('parent', function () {
      if (this.isA('part') && this.kind === "form") {
        return this;
      } else if (this.parent.isA('part')) {
        if (this.parent.kind === "form") {
          return this.parent;
        } else {
          return this.parent.baseForm;
        }
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});