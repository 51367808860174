define("lightway-connect/templates/sites/site/settings/contributors/roles/role/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HcGB+lop",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Delete Role\"],[9],[0,\"\\n\\nAre you sure you want to delete?\\n\\n\"],[7,\"button\",false],[12,\"class\",\"alert button\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"destroyRole\"]],[8],[0,\"\\n  Delete Role\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/sites/site/settings/contributors/roles/role/delete.hbs"
    }
  });

  _exports.default = _default;
});