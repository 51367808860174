define("lightway-connect/components/content-editor/item-region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    editorService: Ember.inject.service(),
    itemRegion: Ember.computed('region', 'region.itemRegionsAsRegion.length', 'editorService.canvasVersion', {
      // itemRegion's item is either a:
      // page-version
      // prototype-based-part
      // data-item
      get: function get() {
        var _this = this;

        if (this._itemRegion) {
          return this._itemRegion;
        }

        var item;
        var itemRegion;

        if (this.region.baseParent.isA('layout-version') || this.region.baseParent.isA('mail-template-version')) {
          item = this.editorService.canvasVersion; // page-version
        }

        if (this.region.baseParent.isA('prototype')) {
          item = this.prototypeBasedPart;
        }

        this.store.peekAll('item-region').forEach(function (iR) {
          if (iR.item && iR.region && iR.item === item && iR.region === _this.region) {
            itemRegion = iR;
          }
        });
        return itemRegion;
      },
      set: function set(key, value) {
        return this._itemRegion = value;
      }
    })
  });

  _exports.default = _default;
});