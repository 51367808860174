define("lightway-connect/components/content-editor/parts/form-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),
    // listGroup: computed(function() {
    //   `form-group-${this.part.identifier}`
    // }),
    listGroup: Ember.computed('editorService.inPageMode', 'part.baseParent', function () {
      if (this.editorService.inPageMode) {
        if (!this.part.baseParent) {
          return "";
        }

        if (this.part.baseParent.isA('item-region')) {
          return "nested-group";
        } else {
          return "restricted";
        }
      }

      return "nested-group";
    })
  });

  _exports.default = _default;
});