define("lightway-connect/components/section-menu-item-children/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentResource: Ember.inject.service(),
    isCurrentRecord: Ember.computed('currentResource.resource', function () {
      return this.currentResource.resource == this.resource;
    })
  });

  _exports.default = _default;
});