define("lightway-connect/components/accounts/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    actions: {
      acceptInvitation: function acceptInvitation() {
        var _this = this;

        this.invitation.save().then(function () {
          // Remove the invitation from store
          _this.store.unloadRecord(_this.invitation); // Reload account to find sites


          _this.currentAccount.load(); // Transition and report


          _this.flashMessages.clearMessages().success('Invitation accepted');

          _this.router.transitionTo('index');
        }).catch(function (reason) {
          _this.presentError(reason);

          _this.router.transitionTo('index');
        });
      },
      declineInvitation: function declineInvitation() {
        var _this2 = this;

        this.invitation.destroyRecord().then(function () {
          _this2.flashMessages.clearMessages().success('Invitation declined');

          _this2.router.transitionTo('index');
        }).catch(function (reason) {
          _this2.presentError(reason);

          _this2.router.transitionTo('index');
        });
      }
    },
    presentError: function presentError(reason) {
      var source = reason.errors.lastObject.source;
      var detail = reason.errors.lastObject.detail;

      if (source) {
        var paths = source.pointer.split('/');
        var attr = paths[paths.length - 1].split('_').join(' ').capitalize();
        this.flashMessages.clearMessages().alert("".concat(attr, " ").concat(detail));
      } else {
        this.flashMessages.clearMessages().alert('There was an error');
      }
    }
  });

  _exports.default = _default;
});