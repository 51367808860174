define("lightway-connect/helpers/dynamic-style-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicStyleProperty = dynamicStyleProperty;
  _exports.default = void 0;

  function dynamicStyleProperty(property, key, inherited) {
    return Ember.computed('editorService.hoverSelection', 'editorService.viewport.{parent,parent.parent}', "".concat(property, ".small.").concat(key), "".concat(property, ".medium.").concat(key), "".concat(property, ".large.").concat(key), function () {
      var viewport = this.editorService.viewport;
      var object = this;

      if (inherited) {
        if (viewport.parent) {
          if (object.get("".concat(property, ".").concat(viewport.parent.size, ".").concat(key)) != null) {
            return object.get("".concat(property, ".").concat(viewport.parent.size, ".").concat(key));
          } else {
            if (viewport.parent.parent) {
              if (object.get("".concat(property, ".").concat(viewport.parent.parent.size, ".").concat(key)) != null) {
                return object.get("".concat(property, ".").concat(viewport.parent.parent.size, ".").concat(key));
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        } else {
          return null;
        }
      } else {
        if (object.get("".concat(property, ".").concat(viewport.size))) {
          if (object.get("".concat(property, ".").concat(viewport.size, ".").concat(key)) != null) {
            return object.get("".concat(property, ".").concat(viewport.size, ".").concat(key));
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  var _default = Ember.Helper.helper(dynamicStyleProperty);

  _exports.default = _default;
});