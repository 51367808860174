define("lightway-connect/templates/components/content-editor/draggable-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MqLq/AjQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"selectable\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"innerGrid\"]]],[8],[0,\"\\n\\n    \"],[7,\"div\",false],[12,\"class\",[23,0,[\"innerCell\"]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[23,0,[\"selectPartAction\"]],[23,0,[\"part\"]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\n      \"],[14,1],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"innerGrid\"]]],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[23,0,[\"innerCell\"]]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/draggable-part.hbs"
    }
  });

  _exports.default = _default;
});