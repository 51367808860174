define("lightway-connect/components/content-editor/part-or-prototype", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    item: Ember.computed('part.basePrototype', function () {
      if (this.part.basePrototype) {
        return this.part.basePrototype.partsSortedBound.get('firstObject');
      } else {
        return this.part;
      }
    })
  });

  _exports.default = _default;
});