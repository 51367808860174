define("lightway-connect/mixins/parent-folderable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    parent: (0, _model.belongsTo)('folderParentable', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});