define("lightway-connect/components/sites/parts/add-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedElement: Ember.inject.service(),
    // Disable wrapper div
    tagName: '',
    actions: {
      addImage: function addImage(pnt) {
        var totalParts = pnt.get('parts.length');
        var imagePart = this.store.createRecord('part', {
          parent: pnt,
          kind: 'pending-image',
          rowOrder: totalParts + 1
        });
        this.selectedElement.select(imagePart);
      }
    }
  });

  _exports.default = _default;
});