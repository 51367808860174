define("lightway-connect/templates/components/content-editor/check-box-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YRI23QAp",
    "block": "{\"symbols\":[\"Group\",\"@model\",\"&default\"],\"statements\":[[5,\"ui-form/check-box-group\",[],[[\"@model\",\"@property\",\"@toggleAction\"],[[23,2,[]],[23,0,[\"propertyPath\"]],[28,\"action\",[[23,0,[]],\"toggleCheckbox\"],null]]],{\"statements\":[[0,\"\\n  \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/check-box-group.hbs"
    }
  });

  _exports.default = _default;
});