define("lightway-connect/templates/components/media/image-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k0FbApZW",
    "block": "{\"symbols\":[\"image\",\"folder\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isSelectingFolder\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"imageFolders\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[12,\"role\",\"button\"],[12,\"class\",\"image-folder\"],[3,\"action\",[[23,0,[]],\"selectFolder\",[23,2,[]]]],[8],[0,\"\\n        \"],[1,[23,2,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isSelectingImage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"folder-back-button\"],[3,\"action\",[[23,0,[]],\"exitFolder\"]],[8],[0,\"\\n    Back\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"selectedFolder\",\"images\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[12,\"role\",\"button\"],[12,\"class\",\"image\"],[3,\"action\",[[23,0,[]],[23,0,[\"selectImage\"]],[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/media/image-picker.hbs"
    }
  });

  _exports.default = _default;
});