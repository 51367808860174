define("lightway-connect/components/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form ...attributes>
    {{yield (hash
      Field=(
        component 'form/field'
        model=@model
        errors=@errors
      )
      Button=(
        component 'form/button'
        type="submit"
        onClick=@submitAction
      )
    )}}
  </form>
  
  */
  {
    id: "lplSLTcq",
    block: "{\"symbols\":[\"&attrs\",\"@submitAction\",\"@errors\",\"@model\",\"&default\"],\"statements\":[[7,\"form\",false],[13,1],[8],[0,\"\\n  \"],[14,5,[[28,\"hash\",null,[[\"Field\",\"Button\"],[[28,\"component\",[\"form/field\"],[[\"model\",\"errors\"],[[23,4,[]],[23,3,[]]]]],[28,\"component\",[\"form/button\"],[[\"type\",\"onClick\"],[\"submit\",[23,2,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lightway-connect/components/form.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});