define("lightway-connect/components/translations-for-target/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VGHOWj6",
    "block": "{\"symbols\":[\"translation\"],\"statements\":[[7,\"span\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"addTranslation\"]],[8],[0,\"\\n  Add Translation\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"translationsLoaded\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"model\",\"translations\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if-translation-belongs-to\",null,[[\"model\",\"targetString\",\"translation\"],[[23,0,[\"model\"]],[24,[\"targetString\"]],[23,1,[]]]],{\"statements\":[[0,\"        \"],[1,[28,\"translation-field\",null,[[\"translation\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/components/translations-for-target/template.hbs"
    }
  });

  _exports.default = _default;
});