define("lightway-connect/components/content-editor/properties/layout-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editorService: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    classNames: ['current-canvas-settings'],
    selectingWebFontDocument: false,
    actions: {
      newColorClass: function newColorClass() {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        var i = globalStyle.get('styleProperties.color-class-ids').length + 1;
        var newColorClassId = "color-class-".concat(i);
        var newColorClass = {
          id: newColorClassId,
          name: "Color Class ".concat(i),
          type: "custom",
          value: "#CCCCCC"
        }; // Collect existing color class ids

        var ids = Ember.A([]);
        globalStyle.get('styleProperties.color-class-ids').forEach(function (id) {
          ids.addObject(id);
        });
        ids.addObject(newColorClassId); // Collect existing color classes

        var colorClasses = Ember.A([]);
        globalStyle.get('styleProperties.color-classes').forEach(function (color) {
          colorClasses.addObject(color);
        });
        colorClasses.addObject(newColorClass);
        var changes = {};
        changes["styleProperties.color-class-ids"] = ids;
        changes["styleProperties.color-classes"] = colorClasses;
        var editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },
      newGlobalFont: function newGlobalFont() {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        var i = globalStyle.globalFonts.length + 1;
        var newGF = {
          value: "Arial",
          category: "sans-serif",
          "webfont-reference": null
        };
        var changes = {};
        changes["styleProperties.gf".concat(i)] = newGF;
        globalStyle.gFontsToWatch.pushObject("gf".concat(i));
        var editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },
      toggleModalForWebFontDocument: function toggleModalForWebFontDocument() {
        this.set('selectingWebFontDocument', !this.get('selectingWebFontDocument'));
      },
      cancelWebFontDocumentSelection: function cancelWebFontDocumentSelection() {
        this.set('selectingWebFontDocument', false);
      },
      selectWebFontDocument: function selectWebFontDocument(path, document) {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        var changes = {};
        changes[path] = document.id;
        var editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
        this.set('selectingWebFontDocument', false);
      },
      removeWebFontDocument: function removeWebFontDocument(path) {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        var changes = {};
        changes[path] = null;
        var editHash = this.editorService.prepareEdit(globalStyle, changes);
        this.editorService.addModifications(Ember.A([editHash]));
      },
      selectDocument: function selectDocument(document, property) {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        globalStyle.set(property, document.id);
      },
      removeDocument: function removeDocument(property) {
        var globalStyle = this.editorService.canvasVersion.globalStyle;
        globalStyle.set(property, null);
      }
    }
  });

  _exports.default = _default;
});