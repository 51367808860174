define("lightway-connect/templates/components/content-editor/part-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rP/8Eqrl",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"cssString\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lightway-connect/templates/components/content-editor/part-css.hbs"
    }
  });

  _exports.default = _default;
});