define("lightway-connect/controllers/sites/site/content/pages/new", ["exports", "lightway-connect/validations/pages/page"], function (_exports, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    PageValidations: _page.default,
    flashMessages: Ember.inject.service(),
    formErrors: Ember.inject.service(),
    router: Ember.inject.service(),
    generatePermalink: function generatePermalink(changeset) {
      if (changeset.get('name')) {
        var permalink = changeset.get('name').replace("'", "").replace(/[_\W]+/g, "-").dasherize();
        changeset.set('permalink', permalink);
      }
    },
    generateRowOrder: function generateRowOrder(changeset) {
      var rowOrder;

      if (changeset.get('parent')) {
        rowOrder = changeset.get('parent.pages.length') + 1;
      } else {
        var rootPages = Ember.A([]);
        this.store.peekAll('page').forEach(function (pg) {
          if (!pg.parent) {
            rootPages.pushObject(pg);
          }
        });
        rowOrder = rootPages.length;
      }

      changeset.set('rowOrder', rowOrder);
    },
    actions: {
      savePage: function savePage(changesetObj) {
        var _this = this;

        this.generatePermalink(changesetObj);
        this.generateRowOrder(changesetObj);
        changesetObj.validate().then(function () {
          if (changesetObj.get("isValid")) {
            changesetObj.save().then(function (page) {
              _this.flashMessages.clearMessages().success('Page created');

              _this.router.transitionTo('sites.site.content.pages.page', page.id);
            }).catch(function (reason) {
              _this.formErrors.addErrors(reason, changesetObj);

              _this.flashMessages.clearMessages().alert("There was an error");
            });
          } else {
            _this.flashMessages.clearMessages().alert("There was an error");
          }
        });
      }
    }
  });

  _exports.default = _default;
});