define("lightway-connect/controllers/sites/site/data-kinds/data-kind/items/item/registrations/index", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentSite: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    deletingPage: false,
    downloadFile: function downloadFile(a) {
      a.click();
    },
    exportAsCSV: function exportAsCSV() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var item = _this.get('model.item');

        return (0, _fetch.default)("/api/backend/registrations.csv?filter[site]=".concat(_this.currentSite.site.id, "&filter[item]=").concat(item.id), {
          method: 'GET',
          headers: {
            "Content-Type": 'text/csv;charset=utf-8',
            "Accept": "text/csv;version=1",
            "Authorization": "Bearer ".concat(_this.session.data.authenticated.token)
          }
        }).then(function (response) {
          return response.blob();
        }).then(function (blob) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "registrations.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

          _this.downloadFile(a);

          a.remove(); //afterwards we remove the element again

          resolve();
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },
    actions: {
      doNothing: function doNothing() {},
      exportAsCSV: function exportAsCSV() {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this2.exportAsCSV().then(function (response) {
            return resolve(response);
          }).catch(function (reason) {
            return reject(reason);
          });
        });
      }
    }
  });

  _exports.default = _default;
});