define("lightway-connect/components/content-editor/checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),
    updateProperty: function updateProperty(event) {
      var changes = {};
      changes[this.propertyPath] = event.target.checked;
      var edit = this.editorService.prepareEdit(this.model, changes);
      this.editorService.addModifications(Ember.A([edit]));
    },
    propertyPath: Ember.computed('property', 'propertyPathOverride', 'editorService.viewport.size', function () {
      if (this.propertyPathOverride) {
        return this.propertyPathOverride;
      }

      if (this.shortProperty) {
        return "preferences." + this.editorService.viewport.size + "." + this.shortProperty;
      } else {
        return this.property;
      }
    })
  });

  _exports.default = _default;
});