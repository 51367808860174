define("lightway-connect/components/if-role-action-permitted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentAccount: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkForPermission();
    },
    checkForPermission: function checkForPermission() {
      // Deny access if currentAccount is associated with role
      var permitted = this.currentAccount.isAssociatedWithRole(this.role) == false;
      this.set('permitted', permitted);
    }
  });

  _exports.default = _default;
});