define("lightway-connect/components/content-editor/draggable-parts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    editorService: Ember.inject.service(),
    listClasses: Ember.computed('orientation', 'directionClasses', function () {
      var finalString = "og ";

      if (this.orientation == 'horizontal') {
        finalString += "grid-x ".concat(this.directionClasses);
      } else {
        finalString += "grid-y ".concat(this.directionXClassReverse, " ").concat(this.directionYClassReverse);
      } // Add parent's Y-align property (e.g. align-center) when part is region and in page mode


      if (this.part && this.part.kind == 'region') {
        if (this.editorService.inPageMode) {
          finalString += this.parentYDirectionClass;
        }
      }

      return finalString;
    }),
    valueFor: function valueFor(part, property, inheritedProperty) {
      if (!part) {
        return null;
      }

      var result = part.get(property) || part.get(inheritedProperty);

      if (this.hoverItem) {
        if (this.hoverItem.get(property)) {
          result = this.hoverItem.get(property);
        } else if (this.hoverItem.get(inheritedProperty)) {
          result = this.hoverItem.get(inheritedProperty);
        }
      }

      return result;
    },
    orientation: Ember.computed('orientationOverride', 'part.{contentDirection,inheritedContentDirection}', 'hoverItem.{contentDirection,inheritedContentDirection}', function () {
      if (this.orientationOverride) {
        return this.orientationOverride;
      }

      if (this.part && this.part.parent.isA('item-region')) {
        var regionPart = this.part.parent.region;
        return this.valueFor(regionPart.parent, 'contentDirection', 'inheritedContentDirection');
      }

      return this.valueFor(this.part, 'contentDirection', 'inheritedContentDirection');
    }),
    heightType: Ember.computed('disableHeightType', 'part.{heightType,inheritedHeightType}', 'hoverItem.{heightType,inheritedHeightType}', function () {
      if (this.disableHeightType) {
        return null;
      }

      return this.valueFor(this.part, 'heightType', 'inheritedHeightType');
    }),
    positionX: Ember.computed('disablePositionX', 'part.{positionX,inheritedPositionX}', 'hoverItem.{positionX,inheritedPositionX}', function () {
      if (this.disablePositionX) {
        return null;
      }

      if (this.part && this.part.kind == 'region') {
        return this.valueFor(this.part.parent, 'positionX', 'inheritedPositionX');
      } else {
        return this.valueFor(this.part, 'positionX', 'inheritedPositionX');
      }
    }),
    positionY: Ember.computed('disablePositionY', 'part.{positionY,inheritedPositionY}', 'hoverItem.{positionY,inheritedPositionY}', function () {
      if (this.disablePositionY) {
        return null;
      }

      return this.valueFor(this.part, 'positionY', 'inheritedPositionY');
    }),
    innerCellClass: Ember.computed('heightType', function () {
      if (this.heightType == 'fixed') {
        return "extended-inner-cell";
      } else {
        return "inner-cell";
      }
    }),
    directionClasses: Ember.computed('positionX', 'positionY', 'contentDirection', function () {
      return "".concat(this.directionXClass, " ").concat(this.directionYClass);
    }),
    directionXClass: Ember.computed('directionClassOverride', 'disableDirectionXClass', 'positionX', 'contentDirection', function () {
      if (this.directionClassOverride) {
        return this.directionClassOverride;
      }

      if (this.disableDirectionXClass) {
        return "";
      }

      if (!this.positionX) {
        return "";
      }

      if (this.positionX) {
        return "align-".concat(this.positionX);
      }

      return null;
    }),
    directionXClassReverse: Ember.computed('positionX', function () {
      if (!this.positionX) {
        return "";
      }

      var reversePositionX;

      switch (this.positionX) {
        case 'left':
          reversePositionX = 'top';
          break;

        case 'center':
          reversePositionX = 'middle';
          break;

        case 'right':
          reversePositionX = 'bottom';
          break;
      }

      return "align-".concat(reversePositionX);
    }),
    directionYClass: Ember.computed('directionYClassOverride', 'disableDirectionYClass', 'positionY', 'contentDirection', function () {
      if (this.directionYClassOverride) {
        return this.directionYClassOverride;
      }

      if (this.disableDirectionYClass) {
        return "";
      }

      if (!this.positionY) {
        return "";
      }

      if (this.positionY) {
        return "align-".concat(this.positionY);
      }

      return null;
    }),
    directionYClassReverse: Ember.computed('positionY', 'contentDirection', function () {
      if (!this.positionY) {
        return "";
      }

      var reversePositionY;

      switch (this.positionY) {
        case 'top':
          reversePositionY = 'left';
          break;

        case 'middle':
          reversePositionY = 'center';
          break;

        case 'bottom':
          reversePositionY = 'right';
          break;
      }

      return "align-".concat(reversePositionY);
    })
  });

  _exports.default = _default;
});